import axios from 'axios';
import { IClientUser } from 'shared/interfaces/Admin';
import { ICustodialLevel, ICustodialScenario } from 'shared/interfaces/Custodian';
import { User } from './classes/auth/user';
import { ISite } from './classes/location/site';
import { IBuilding } from './classes/location/building';
import { ISpace } from './classes/location/space';
import { ICustodialZone } from './classes/custodian/custodialZone';
import { ICustodialCustodian } from './classes/custodian/custodialCustodian';
import { ICustodialProfile } from './classes/custodian/custodialProfile';
import { ICustodialRoomUse } from './classes/custodian/custodialRoomuse';
import { ICustodialTask } from './classes/custodian/custodialTask';
import { ICustodialTeam } from './classes/custodian/custodialTeam';
import { IEditorFlooring } from './classes/editor/editorFlooring';
import { IEditorRoomUse } from './classes/editor/editorRoomUse';
import { parentAppState } from './parentAppState';
import { IOrganization } from './classes/superAdmin/organization';
import { IEditorReportData } from './classes/editor/editorReport';
import { ISlab } from './classes/location/slab';
import { IFloor } from './classes/location/floor';

// @ts-ignore
axios.defaults.baseURL = import.meta.env.VITE_API;

function simpleGet<T>(url: string): Promise<T> {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((result) => {
        resolve(result.data as T);
      })
      .catch((err) => {
        parentAppState.errorState.addError(err);
        reject(err);
      });
  });
}

function simplePost<T>(url: string, body: { [key: string]: any }): Promise<T> {
  return new Promise((resolve, reject) => {
    axios
      .post(url, body)
      .then((result) => {
        resolve(result.data as T);
      })
      .catch((err) => {
        parentAppState.errorState.addError(err);
        reject(err);
      });
  });
}

function simplePatch<T>(url: string, body: { [key: string]: any }): Promise<T> {
  return new Promise((resolve, reject) => {
    axios
      .patch(url, body)
      .then((result) => {
        resolve(result.data as T);
      })
      .catch((err) => {
        parentAppState.errorState.addError(err);
        reject(err);
      });
  });
}

function queryObjectToURI(query: { [key: string]: string | string[] }) {
  return Object.entries(query)
    .filter((pair) => {
      if (pair[1]) {
        if (Array.isArray(pair[1])) {
          return !!pair[1].length;
        }
        return true;
      }
      return false;
    })
    .map((pair) => pair.join('='))
    .join('&');
}

export default class ApiCalls {
  static setToken({ token }: { token: string }) {
    window.localStorage.setItem('token', token);
    axios.defaults.headers.common.Authorization = `Basic ${token}`;
  }

  static loadToken() {
    const token = window.localStorage.getItem('token');
    axios.defaults.headers.common.Authorization = `Basic ${token}`;
  }

  static login({ email, password }: { password: string; email: string }) {
    return simplePost<{ token: string }>('/api/v0/account/auth/login', {
      email,
      password,
    });
  }

  static logout() {
    return simplePost<any>('/api/v0/account/auth/logout', {});
  }

  static getUser() {
    return simpleGet<IClientUser>('/api/v0/account/auth/user');
  }

  static getOrganizations() {
    return simpleGet<IOrganization[]>('/api/v0/account/organizations');
  }

  static patchUser(user: User, patch: any) {
    return simplePatch<IClientUser>(`/api/v0/account/users/${user.id}`, patch);
  }

  static getSites(): Promise<ISite[]> {
    return simpleGet('/api/v0/location/sites');
  }

  static getBuildings({ site_id }: { site_id: string }): Promise<IBuilding[]> {
    return simpleGet(`/api/v0/location/${site_id}/buildings`);
  }

  static getSpaces({
    site_id,
    bldg_id,
  }: {
    site_id: string;
    bldg_id: string;
  }): Promise<ISpace[]> {
    return simpleGet(`/api/v0/location/${site_id}/buildings/${bldg_id}/spaces`);
  }

  static getSlabs({
    site_id,
    bldg_id,
  }: {
    site_id: string;
    bldg_id: string;
  }): Promise<ISlab[]> {
    return simpleGet(`/api/v0/location/${site_id}/buildings/${bldg_id}/slabs`);
  }

  static getFloors({
    site_id,
    bldg_id,
  }: {
    site_id: string;
    bldg_id: string;
  }): Promise<IFloor[]> {
    return simpleGet(`/api/v0/location/${site_id}/buildings/${bldg_id}/floors`);
  }

  /* ------ DABBLE EDITOR ------ */
  static getEditorReport({
    site_id,
    startDate,
    endDate,
    reportType,
    simplifyPoints,
    query,
  }: {
    site_id: string;
    startDate: number;
    endDate: number;
    reportType: string;
    simplifyPoints: boolean;
    query: any;
  }) {
    const stringQuery = `simplifyPoints=${simplifyPoints}&${queryObjectToURI(
      query,
    )}`;
    return simpleGet<{ data: IEditorReportData[] }>(
      `/api/v0/editor/${site_id}/reports/${reportType}?${stringQuery}`,
    );
  }

  /* ------ DABBLE CUSTODIAN ------ */
  static postCustodialTypes({
    site_id,
    scenario_id,
    types,
  }: {
    site_id: string;
    scenario_id: string;
    types: Partial<ICustodialTeam>[];
  }): Promise<ICustodialTeam[]> {
    return simplePost(`/api/v0/custodian/${site_id}/scenarios/${scenario_id}/teams`, types);
  }

  static postCustodialLevels({
    site_id,
    scenario_id,
    levels,
  }: {
    site_id: string;
    scenario_id: string;
    levels: Partial<ICustodialLevel>[];
  }): Promise<ICustodialLevel[]> {
    return simplePost(`/api/v0/custodian/${site_id}/scenarios/${scenario_id}/levels`, levels);
  }

  static postCustodialTasks({
    site_id,
    scenario_id,
    tasks,
  }: {
    site_id: string;
    scenario_id: string;
    tasks: Partial<ICustodialTask>[];
  }): Promise<ICustodialTask[]> {
    return simplePost(`/api/v0/custodian/${site_id}/scenarios/${scenario_id}/tasks`, tasks);
  }

  static postCustodialProfiles({
    site_id,
    scenario_id,
    profiles,
  }: {
    site_id: string;
    scenario_id: string;
    profiles: Partial<ICustodialProfile>[];
  }): Promise<any> {
    return simplePost(`/api/v0/custodian/${site_id}/scenarios/${scenario_id}/profiles`, profiles);
  }

  static getCustodialScenarios({
    site_id,
  }: {
    site_id: string;
  }): Promise<ICustodialScenario[]> {
    return simpleGet(`/api/v0/custodian/${site_id}/scenarios`);
  }

  static getCustodialZones({
    site_id,
    scenario_id,
  }: {
    site_id: string;
    scenario_id: string;
  }): Promise<ICustodialZone[]> {
    return simpleGet(
      `/api/v0/custodian/${site_id}/scenarios/${scenario_id}/zones`,
    );
  }

  static getCustodialCustodians({
    site_id,
    scenario_id,
  }: {
    site_id: string;
    scenario_id: string;
  }): Promise<ICustodialCustodian[]> {
    return simpleGet(
      `/api/v0/custodian/${site_id}/scenarios/${scenario_id}/custodians`,
    );
  }

  static getCustodialProfiles({
    site_id,
    scenario_id,
  }: {
    site_id: string;
    scenario_id: string;
  }): Promise<ICustodialProfile[]> {
    return simpleGet(
      `/api/v0/custodian/${site_id}/scenarios/${scenario_id}/profiles`,
    );
  }

  static getCustodialRoomUses({
    site_id,
    scenario_id,
  }: {
    site_id: string;
    scenario_id: string;
  }): Promise<ICustodialRoomUse[]> {
    return simpleGet(
      `/api/v0/custodian/${site_id}/scenarios/${scenario_id}/roomUses`,
    );
  }

  static getCustodialTasks({
    site_id,
    scenario_id,
  }: {
    site_id: string;
    scenario_id: string;
  }): Promise<ICustodialTask[]> {
    return simpleGet(
      `/api/v0/custodian/${site_id}/scenarios/${scenario_id}/tasks`,
    );
  }

  static getCustodialTeams({
    site_id,
    scenario_id,
  }: {
    site_id: string;
    scenario_id: string;
  }): Promise<ICustodialTeam[]> {
    return simpleGet(
      `/api/v0/custodian/${site_id}/scenarios/${scenario_id}/teams`,
    );
  }

  static getEditorFlooring({
    site_id,
  }: {
    site_id: string;
  }): Promise<IEditorFlooring[]> {
    return simpleGet(`/api/v0/editor/${site_id}/flooring`);
  }

  static getEditorRoomUses({
    site_id,
  }: {
    site_id: string;
  }): Promise<IEditorRoomUse[]> {
    return simpleGet(`/api/v0/editor/${site_id}/roomUses`);
  }
}
