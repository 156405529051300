import { makeAutoObservable } from 'mobx';
import { IExcelColumn } from 'shared/interfaces/Editor/IExcelColumn';
import { ISheetColumn } from 'shared/interfaces/Editor/ISheetColumn';

export class ExcelColumn {
  public name: string;

  public variants: string[];

  public path: string;

  public sample?: any;

  public required?: boolean = true;

  public header?: ISheetColumn;

  public type?: string;

  constructor(params: IExcelColumn) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.name = params.name;
    this.variants = params.variants;
    this.path = params.path;
    this.sample = params.sample;
    this.required = params.required ?? true;
    this.type = params.type;
    this.header = params.header;
  }

  public get allVariants() {
    return [this.name]
      .concat(this.variants)
      .map((x) => x?.trim().toLowerCase())
      .filter((x) => x);
  }

  public guessHeader(headers: ISheetColumn[]) {
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      const headerTitle = header.label.toLowerCase().trim();
      if (this.allVariants.indexOf(headerTitle) > -1) {
        this.header = header;
        return;
      }
    }
  }

  public setHeader(header: ISheetColumn) {
    this.header = header;
  }
}
