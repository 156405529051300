import ApiCalls from '../../apiCalls';
import { Building, IBuilding } from './building';
import { ParentAppState } from '../../parentAppState';
import { EditorState } from '../../states/editorState';
import { CustodianState } from '../../states/custodianState';

export interface ISite {
  _id: string;
  name: string;
  coverImage: {
    url: string;
  };
  area: number;
}

export class Site {
  public readonly _id: string;

  private _name: string;

  private _buildings: Building[] = [];

  private _image: string;

  private _area: number;

  public currentBuilding?: Building;

  public editorState: EditorState = new EditorState(this);

  public custodianState: CustodianState = new CustodianState(this);

  public appState: ParentAppState;

  public ready: boolean = false;

  constructor(
    {
      _id, name, coverImage, area,
    }: ISite,
    appState: ParentAppState,
  ) {
    this._id = _id;
    this._name = name;
    this._image = coverImage?.url || '';
    this._area = area;

    this.appState = appState;
  }

  initialize() {
    Promise.all([
      this.editorState.fetch(),
      this.custodianState.fetch(),
      this.fetchBuildings(),
    ])
      .then(() => {
        this.setReady(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  setReady(state: boolean) {
    this.ready = state;
  }

  public getBuildingById(bldg_id: string): Promise<Building | undefined> {
    return new Promise((resolve, reject) => {
      if (this.ready) {
        resolve(this.buildings.find((building) => building.id === bldg_id));
      } else {
        this.fetchBuildings()
          .then(() => {
            resolve(this.buildings.find((building) => building.id === bldg_id));
          })
          .catch((err) => reject(err));
      }
    });
  }

  setCurrentBuildingByBldg_id(bldg_id: string) {
    const building = this.buildings.find((building) => building.id === bldg_id);
    if (building && this.currentBuilding !== building) this.setCurrentBuilding(building);
  }

  setCurrentBuilding(building?: Building) {
    building?.fetchSpaces();
    this.currentBuilding = building;
  }

  fetchBuildings() {
    return ApiCalls.getBuildings({ site_id: this.id }).then(
      async (iBuildings: IBuilding[]) => {
        const buildings = iBuildings.map(
          (building) => new Building({ ...building, site: this }),
        );
        await this.setBuildings(buildings);

        if (this.appState.params.bldg_id) {
          this.setCurrentBuilding(
            buildings.find(
              (building) => building.id === this.appState.params.bldg_id,
            ),
          );
        }
      },
    );
  }

  setBuildings(buildings: Building[]) {
    this._buildings = buildings.sort((a, b) => a.number.localeCompare(b.number, 'en', { numeric: true }));
  }

  public get id() {
    return this._id;
  }

  public get name() {
    return this._name;
  }

  public get buildings() {
    return this._buildings;
  }

  public get image() {
    return this._image;
  }

  public get area() {
    return this._area;
  }

  public get searchName() {
    return this._name.toLowerCase();
  }
}
