import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { InspectionIcon } from 'client/components/CustomIcons//InspectionsIcon';
import { DashboardIcon } from 'client/components/CustomIcons//DashboardIcon';
import { UsersIcon } from 'client/components/CustomIcons//UsersIcon';
import { ToolboxIcon } from 'client/components/CustomIcons//ToolboxIcon';
import { BaseModule } from './base';

export class ModuleSuperAdmin extends BaseModule {
  constructor() {
    super({
      name: 'SuperADMIN',
      shortName: 'SUPER',
      baseRoute: 'superAdmin',
      icon: RocketLaunchIcon,
      textColor: '#222',
      secondaryTextColor: '#fff',
      secondaryColor: '#ffd91b',
      backgroundColor: '#ffff00',
      navButtons: [
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'Organizations',
          icon: UsersIcon,
          route: '/superAdmin/organizations',
          matchRoutes: ['/superAdmin/organizations/:org_id'],
        },
        {
          type: 'nav',
          text: 'Toolbox',
          icon: ToolboxIcon,
          route: '/superAdmin/toolbox',
          matchRoutes: ['/superAdmin/toolbox/:toolName'],
        },
        {
          type: 'nav',
          text: 'Status',
          icon: DashboardIcon,
          route: '/superAdmin/status',
        },
        // {
        //   type: 'nav',
        //   text: 'Logs',
        //   icon: DashboardIcon,
        //   route: '/superAdmin/logs',
        // },
      ],
    });
  }

  protected setup() {
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }
}
