import React from 'react';
import './login.css';
import { LoginWrapper } from './loginWrapper';

export const NoServicePage = () => (
  <LoginWrapper>
    <p>
      Sorry, this service is not available at this time. Please try again in a
      few minutes
    </p>
  </LoginWrapper>
);
