import React, { useState } from 'react';
import { SetPassword } from 'client/components/login/setPassword';
import { LoginComponent } from './loginComponent';
import './login.css';
import { LoginWrapper } from './loginWrapper';
import { LoginForgotPassword } from './forgotPassword';

export const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [page, setPage] = useState('');

  let contents;

  switch (page) {
    case 'forgotPassword':
      contents = (
        <LoginForgotPassword
          email={email}
          setEmail={setEmail}
          setPage={setPage}
        />
      );
      break;
    case 'setPassword':
      contents = (
        <SetPassword />
      );
      break;
    default:
      contents = (
        <LoginComponent
          email={email}
          setEmail={setEmail}
          setPage={setPage}
        />
      );
      break;
  }

  return <LoginWrapper>{contents}</LoginWrapper>;
};
