import { Building } from './building';
import { EditorRoomUse } from '../editor/editorRoomUse';
import { EditorFlooring } from '../editor/editorFlooring';

export interface ISpace {
  _id: string;
  number: number | string;
  name: string;
  bldg: Building;
  floorIndex: number;
  area: number;
  hidden?: boolean;
  map: [number, number][][];
}

export class Space {
  private readonly _id: string;

  private readonly _bldg: Building;

  private _name: string;

  private _number: string;

  private _roomUse?: EditorRoomUse;

  private _flooring?: EditorFlooring;

  private _area?: number;

  private _hidden: boolean;

  private _selected: boolean = false;

  private readonly _floorIndex?: number;

  private readonly _map?: [number, number][][];

  constructor({
    _id,
    number,
    name,
    bldg,
    floorIndex,
    area,
    map,
    hidden,
  }: ISpace) {
    this._id = _id;
    this._bldg = bldg;
    this._number = (number || '').toString();
    this._name = name;
    this._floorIndex = floorIndex;
    this._area = area;
    this._map = map;
    this._hidden = !!hidden;
  }

  public setSelected(selected: boolean) {
    this._selected = selected;
  }

  public toggleSelected() {
    this.setSelected(!this.selected);
  }

  public get id() {
    return this._id;
  }

  public get bldg() {
    return this._bldg;
  }

  public get number() {
    return this._number;
  }

  public get name() {
    return this._name;
  }

  public get area() {
    return this._area;
  }

  public get selected() {
    return this._selected;
  }

  public get hidden() {
    return this._hidden;
  }

  public get floorIndex() {
    return this._floorIndex;
  }

  public get map() {
    return this._map;
  }

  public get label() {
    return [this.number, this.name].filter((val) => val).join(' - ');
  }

  public get value() {
    return this.id;
  }

  // public get qc(){return this._bldg.site.qcState.spacesHash[this.id]}
}
