import { makeAutoObservable } from 'mobx';
import { Organization } from '../classes/superAdmin/organization';
import { ParentAppState } from '../parentAppState';
import ApiCalls from '../apiCalls';
import { nameSort } from '../functions/nameSort';

export class SuperAdminState {
  public organizations: Organization[] = [];

  private _appState: ParentAppState;

  constructor(appState: ParentAppState) {
    makeAutoObservable(this, {}, { autoBind: true });
    this._appState = appState;
    this.fetch();
  }

  private fetch() {
    ApiCalls.getOrganizations().then((orgs) => {
      this.setOrganizations(orgs.map((val) => new Organization(val)));
    });
  }

  setOrganizations(organizations: Organization[]) {
    this.organizations = nameSort(organizations);
    this.validateSiteAndOrg();
  }

  get currentOrganization() {
    return this.organizations.find((org) => org.id === this._appState.user?.org_id);
  }

  public validateSiteAndOrg() {
    if (this._appState.params && this._appState.params.site_id) {
      if (
        this.currentOrganization
        && !this.currentOrganization.hasSite(this._appState.params.site_id)
      ) {
        const newOrg = this.organizations.find((val) => val.hasSite(this._appState.params.site_id));

        if (newOrg) {
          this._appState.user?.setOrg(newOrg, true);
        }
      }
    }
  }
}
