import React from 'react';

// @ts-ignore
export const ConsumablesIcon = () => (
  <svg
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    height="100%"
    width="100%"
  >
    <title id="consumablesIconTitle">Consumables Icon</title>
    <desc id="consumablesIconDesc">Consumables Icon</desc>

    <path
      d="M347.583,111.364c-1.641,1.146-3.365,2.255-5.198,3.346c-0.248,0.147-0.468,0.303-0.724,0.44
			c-2.081,1.21-4.291,2.375-6.592,3.521c-0.532,0.266-1.082,0.523-1.623,0.788c-2.045,0.981-4.162,1.935-6.354,2.861
			c-0.394,0.165-0.761,0.339-1.155,0.495c-2.549,1.054-5.199,2.063-7.931,3.044c-0.596,0.211-1.21,0.422-1.825,0.633
			c-2.393,0.834-4.85,1.641-7.371,2.42c-0.513,0.156-1.018,0.321-1.54,0.486c-2.952,0.889-5.987,1.751-9.095,2.567
			c-0.633,0.165-1.284,0.33-1.925,0.495c-2.668,0.688-5.391,1.348-8.169,1.98c-0.669,0.156-1.329,0.312-1.999,0.458
			c-3.273,0.724-6.601,1.421-10.003,2.081c-0.633,0.119-1.274,0.238-1.916,0.358c-2.943,0.55-5.923,1.082-8.948,1.586
			c-0.77,0.128-1.531,0.257-2.31,0.385c-3.548,0.568-7.133,1.1-10.764,1.604c-0.568,0.073-1.137,0.147-1.705,0.22
			c-3.172,0.422-6.363,0.816-9.599,1.183c-0.898,0.101-1.788,0.202-2.686,0.303c-3.713,0.403-7.463,0.77-11.231,1.109
			c-0.504,0.046-1.009,0.083-1.513,0.119c-3.365,0.284-6.739,0.541-10.14,0.761c-0.972,0.064-1.944,0.128-2.925,0.193
			c-3.842,0.238-7.692,0.449-11.57,0.614c-0.321,0.009-0.642,0.018-0.963,0.037c-3.594,0.147-7.197,0.266-10.81,0.348
			c-1.018,0.028-2.035,0.046-3.053,0.064c-3.915,0.073-7.83,0.128-11.745,0.128s-7.83-0.046-11.745-0.128
			c-1.018-0.018-2.035-0.037-3.053-0.064c-3.612-0.083-7.216-0.202-10.81-0.348c-0.321-0.009-0.642-0.018-0.963-0.037
			c-3.869-0.165-7.729-0.376-11.571-0.614c-0.981-0.064-1.953-0.128-2.925-0.193c-3.401-0.229-6.785-0.486-10.14-0.77
			c-0.504-0.046-1.009-0.083-1.513-0.119c-3.777-0.33-7.518-0.697-11.231-1.109c-0.898-0.101-1.797-0.202-2.695-0.303l-0.495-0.055
			l10.525,15.32c0.037,0.055,0.046,0.128,0.083,0.193c0.284,0.523,0.523,1.073,0.697,1.641c0.266,0.523,0.486,1.073,0.651,1.641
			c0.018,1.155,0.018,2.31,0,3.466c-0.174,0.605-0.403,1.192-0.697,1.76c-0.165,0.532-0.385,1.036-0.651,1.522
			c-0.037,0.064-0.046,0.138-0.083,0.193l-14.779,21.555l14.779,21.555l0.055,0.138c0.348,0.614,0.623,1.256,0.816,1.935
			c0.22,0.44,0.394,0.898,0.541,1.375c0.018,1.164,0.018,2.329,0,3.493c-0.128,0.413-0.293,0.816-0.477,1.21
			c-0.202,0.733-0.504,1.439-0.889,2.09l-0.055,0.128l-14.779,21.564l14.779,21.555l0.055,0.128c0.376,0.651,0.669,1.348,0.88,2.063
			c0.193,0.403,0.358,0.816,0.486,1.238c0.018,1.164,0.018,2.329,0,3.493c-0.138,0.422-0.293,0.834-0.486,1.238
			c-0.202,0.724-0.495,1.421-0.88,2.063l-0.055,0.128l-14.779,21.555l14.779,21.564l0.055,0.128c0.385,0.66,0.678,1.357,0.889,2.09
			c0.183,0.394,0.348,0.798,0.477,1.21c0.018,1.164,0.018,2.329,0,3.493c-0.147,0.468-0.33,0.926-0.541,1.375
			c-0.193,0.678-0.468,1.32-0.816,1.935l-0.055,0.138l-14.779,21.555l14.779,21.555l0.055,0.138
			c0.348,0.614,0.623,1.256,0.816,1.935c0.22,0.44,0.394,0.898,0.541,1.375c0.018,1.164,0.018,2.329,0,3.493
			c-0.128,0.413-0.293,0.816-0.477,1.21c-0.202,0.734-0.504,1.439-0.889,2.09l-0.055,0.128l-14.779,21.564l14.779,21.555
			c0.128,0.266,0.248,0.541,0.348,0.825c0.596,1.082,0.963,2.274,1.082,3.493c0.073,0.284,0.128,0.578,0.174,0.862v1.559
			c-0.046,0.293-0.101,0.587-0.174,0.88c-0.119,1.229-0.486,2.42-1.082,3.502c-0.101,0.275-0.22,0.55-0.348,0.816l-14.77,21.5
			l9.865,14.394c20.436,2.164,40.974,3.227,61.529,3.2c107.921,0,174.2-26.698,174.2-45.842V110.841
			C348.142,111.024,347.84,111.189,347.583,111.364z"
    />
    <path
      d="M84.12,453.226l15.311-22.261L84.12,408.631c-2.136-3.126-2.136-7.243,0-10.37L98.9,376.697L84.12,355.142
			c-2.136-3.126-2.136-7.243,0-10.37L98.9,323.218L84.12,301.654c-2.136-3.126-2.136-7.243,0-10.37L98.9,269.729L84.12,248.174
			c-2.136-3.126-2.136-7.243,0-10.37L98.9,216.241L84.12,194.686c-2.136-3.126-2.136-7.243,0-10.369l14.789-21.564l-14.779-21.509
			c-0.495-0.733-0.889-1.54-1.155-2.384c-2.952-0.495-5.868-1.009-8.747-1.549c-0.633-0.119-1.284-0.229-1.916-0.358
			c-3.392-0.66-6.73-1.348-10.003-2.081c-0.669-0.147-1.329-0.312-1.999-0.458c-2.778-0.633-5.501-1.293-8.169-1.98
			c-0.642-0.165-1.293-0.321-1.925-0.495c-3.108-0.825-6.143-1.678-9.095-2.567c-0.523-0.156-1.027-0.321-1.54-0.486
			c-2.521-0.779-4.978-1.586-7.371-2.42c-0.605-0.211-1.219-0.422-1.825-0.633c-2.732-0.981-5.391-1.99-7.931-3.044
			c-0.394-0.165-0.77-0.33-1.155-0.495c-2.2-0.926-4.318-1.88-6.354-2.861c-0.541-0.257-1.091-0.523-1.623-0.788
			c-2.292-1.137-4.511-2.31-6.592-3.521c-0.248-0.147-0.477-0.293-0.724-0.44c-1.825-1.082-3.548-2.2-5.198-3.337
			c-0.248-0.156-0.55-0.321-0.807-0.504v319.344c0,13.331,32.227,30.311,88.209,39.397l-4.098-5.978
			C81.966,460.478,81.975,456.352,84.12,453.226z"
    />
    <ellipse cx="174.2" cy="81.786" rx="61.685" ry="9.168" />
    <path
      d="M496.698,398.261L512,375.936l-15.302-22.316c-2.136-3.126-2.136-7.243,0-10.369l14.779-21.564l-14.779-21.555
			c-2.136-3.126-2.136-7.243,0-10.37l14.779-21.555l-14.779-21.564c-2.136-3.126-2.136-7.243,0-10.369l14.779-21.555l-14.779-21.555
			c-2.136-3.126-2.136-7.243,0-10.369l14.779-21.564l-14.779-21.555c-2.136-3.126-2.136-7.243,0-10.37l14.779-21.555l-14.779-21.564
			c-2.136-3.126-2.136-7.243,0-10.369l8.499-12.386H359.97c4.282,5.162,6.675,11.635,6.775,18.337v339.231h138.452l-8.499-12.386
			C494.553,405.504,494.553,401.388,496.698,398.261z"
    />
    <path
      d="M330.099,62.881c-0.715-0.248-1.394-0.578-2.017-0.999c-27.349-13.78-81.085-25.937-153.882-25.937
			C66.278,35.944,0,62.643,0,81.786c0,13.963,35.399,31.906,96.397,40.616c0.174,0.028,0.339,0.037,0.513,0.073
			c25.617,3.521,51.444,5.244,77.29,5.153c107.921,0,174.2-26.698,174.2-45.842C348.399,75.873,342.036,69.235,330.099,62.881z
			 M174.2,109.292c-13.78,0-82.516-1.329-82.516-27.505c0-26.176,68.735-27.505,82.516-27.505c13.78,0,82.516,1.329,82.516,27.505
			S187.98,109.292,174.2,109.292z"
    />
  </svg>
);
