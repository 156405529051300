import { makeAutoObservable } from 'mobx';
import {
  IClientUser,
  IModuleUserPermission,
  IUserEmails,
  IUserPermissions,
  IUserProfile,
} from 'shared/interfaces/Admin';

export class User {
  public org_id: string;

  public _id: string;

  public emails: IUserEmails[];

  public profile: IUserProfile;

  public createdAt: string;

  public lastActive: number;

  public permissions: IUserPermissions;

  constructor(params: IClientUser) {
    makeAutoObservable(this);

    this.org_id = params.org_id;
    this._id = params._id;
    this.emails = params.emails;
    this.profile = params.profile;
    this.createdAt = params.createdAt as string;
    this.lastActive = params.lastActive ?? 0;
    this.permissions = params.permissions;
  }

  public get email() {
    return this.emails[0].address;
  }

  public get name() {
    return [this.profile.firstName, this.profile.lastName]
      .filter((x) => x)
      .join(' ');
  }

  private getPermissionLabel(permission?: IModuleUserPermission) {
    if (permission?.write) return 'WRITE';
    if (permission?.read) return 'READ';
    return '';
  }

  public get dabbleCUSTODIAN() {
    return this.getPermissionLabel(this.permissions.modules.dabbleCUSTODIAN);
  }

  public get dabbleQC() {
    return this.getPermissionLabel(this.permissions.modules.dabbleQC);
  }

  public get dabbleSPACE() {
    return this.getPermissionLabel(this.permissions.modules.dabbleSPACE);
  }

  public get dabbleSHELF() {
    return this.getPermissionLabel(this.permissions.modules.dabbleSHELF);
  }

  public get dabbleEDITOR() {
    return this.getPermissionLabel(this.permissions.modules.dabbleEDITOR);
  }
}
