import { BaseModule } from 'client/utilities/modules/base';
import PublicIcon from '@mui/icons-material/Public';
import { InspectionIcon } from 'client/components/CustomIcons/InspectionsIcon';
import { ScenariosIcon } from 'client/components/CustomIcons/ScenariosIcon';
import { RoomsIcon } from 'client/components/CustomIcons/RoomsIcon';
import { BuildingsIcon } from 'client/components/CustomIcons/BuildingsIcon';
import { SettingsIcon } from 'client/components/CustomIcons/SettingsIcon';
import { ReportsIcon } from 'client/components/CustomIcons/ReportsIcon';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export class ModuleSpace extends BaseModule {
  constructor() {
    super({
      name: 'DabbleSPACE',
      shortName: 'SPACE',
      baseRoute: 'space',
      icon: CalendarMonthIcon,
      textColor: '#f8f8f8',
      secondaryTextColor: '#fff',
      secondaryColor: '#625192',
      backgroundColor: '#281758',
      navButtons: [
        {
          type: 'site',
          text: 'Site',
          icon: PublicIcon,
          route: '/space/sites/',
          quickPickType: 'site',
          matchRoutes: ['/space/sites/:site_id', '/space/sites'],
        },
        {
          type: 'nav',
          text: 'Scenarios',
          icon: ScenariosIcon,
          route: '/space/sites/:site_id/scenarios',
          requiredParams: ['site_id'],
          matchRoutes: ['/space/sites/:site_id/scenarios/:scenario_id'],
        },
        {
          type: 'nav',
          text: 'Buildings',
          icon: BuildingsIcon,
          route: '/space/sites/:site_id/scenarios/:scenario_id/buildings',
          requiredParams: ['scenario_id', 'site_id'],
          matchRoutes: [
            '/space/sites/:site_id/scenarios/:scenario_id/buildings/:bldg_id',
          ],
        },
        {
          type: 'nav',
          text: 'Rooms',
          icon: RoomsIcon,
          route:
            '/space/sites/:site_id/scenarios/:scenario_id/buildings/:bldg_id/spaces',
          requiredParams: ['site_id', 'scenario_id', 'bldg_id'],
          matchRoutes: [
            '/space/sites/:site_id/scenarios/:scenario_id/buildings/:bldg_id/spaces',
          ],
        },
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'Reports',
          icon: ReportsIcon,
          route: '/space/sites/:site_id/scenarios/:scenario_id/reports',
          // requiredParams: ['scenario_id', 'site_id', 'ALWAYS_DISABLED'],
          requiredParams: ['site_id', 'scenario_id'],
        },
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'Settings',
          icon: SettingsIcon,
          route: '/space/sites/:site_id/scenarios/:scenario_id/settings',
          requiredParams: ['site_id', 'scenario_id'],
          matchRoutes: [
            '/space/sites/:site_id/scenarios/:scenario_id/settings/options',
            '/space/sites/:site_id/scenarios/:scenario_id/settings/access',
            '/space/sites/:site_id/scenarios/:scenario_id/settings/roomUses',
            '/space/sites/:site_id/scenarios/:scenario_id/settings/topCodes',
            '/space/sites/:site_id/scenarios/:scenario_id/settings/time',
            '/space/sites/:site_id/scenarios/:scenario_id/settings/types',
          ],
        },
      ],
    });
  }

  // eslint-disable-next-line
  protected setup() {
    // eslint-disable-next-line
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }
}
