import { ApiCalls } from 'client/Analyze/Utilities/ApiCalls';
import { makeAutoObservable, runInAction } from 'mobx';
import { IEditorSlab } from 'client/modules/editor/Utilities/Interfaces';
import { IEditorRoomUse } from 'client/utilities/classes/editor/editorRoomUse';
import { ICustodialRoomUse } from 'client/utilities/classes/custodian/custodialRoomuse';
import { IEditorFlooring } from 'client/utilities/classes/editor/editorFlooring';
import { IClientUser } from 'shared/interfaces/Admin';
import { IEditor_Floor, IEditor_Site } from 'shared/interfaces/Editor';
import { ICustodialTask } from 'shared/interfaces/Custodian';
import { ICustodialSpaceClient } from './Classes/CustodialSpace';
import { ICustodialBuilding } from './Classes/CustodialBuilding';
import { ICustodialTeam } from './Classes/ICustodialTeam';
import { ICustodialZone } from './Classes/ICustodialZone';
import { ICustodialScenario } from 'shared/interfaces/Custodian';
import { ICustodialLevel } from './Classes/ICustodialLevel';
import { ICustodialProfile } from './Classes/ICustodialProfile';
import { CustodialCustodian, ICustodialCustodian } from './Classes/CustodialCustodian';
import { User } from '../../admin/Utilities/Classes/User';
import { ICustodialTaskFFE } from './Classes/ICustodialTaskFFE';
import { ICustodialScenarioTemplateHolder } from './Classes/ICustodialScenarioTemplate';
import { ApiCallLoader } from '../../superAdmin/Utilities/ApiCallLoader';
import { CustodialZone } from './Classes/CustodialZone';

class CustodialAppState {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.site_id = undefined;
    this.scenario_id = undefined;
    this.scenario = undefined;

    this.fetchSites();
  }

  sites?: IEditor_Site[];

  site_id?: string;

  scenario_id?: string;

  scenario?: ICustodialScenario;

  scenarios?: ICustodialScenario[];

  templateScenarios?: ICustodialScenarioTemplateHolder;

  token?: string;

  custodians?: CustodialCustodian[] = [];

  buildings?: ICustodialBuilding[] = [];

  spaces?: ICustodialSpaceClient[] = [];

  slabs: IEditorSlab[] = [];

  floors: IEditor_Floor[] = [];

  profiles: ICustodialProfile[] = [];

  tasks: ICustodialTask[] = [];

  zones: CustodialZone[] = [];

  levels: ICustodialLevel[] = [];

  teams: ICustodialTeam[] = [];

  tasksFFE: ICustodialTaskFFE[] = [];

  selectedTeam?: ICustodialTeam;

  users: User[] = [];

  setSelectedTeam(team?: ICustodialTeam) {
    this.selectedTeam = team;
  }

  public editorRoomUses = new ApiCallLoader<IEditorRoomUse>({
    url: 'api/v0/editor/:site_id/roomUses',
    verb: 'get',
    sort: (a, b) => a?.name.localeCompare(b.name, 'en', { numeric: true }),
  });

  public editorFloorings = new ApiCallLoader<IEditorFlooring>({
    url: 'api/v0/editor/:site_id/flooring',
    verb: 'get',
    sort: (a, b) => a?.name.localeCompare(b.name, 'en', { numeric: true }),
  });

  public custodialRoomUses = new ApiCallLoader<ICustodialRoomUse>({
    url: 'api/v0/custodian/:site_id/scenarios/:scenario_id/roomUses',
    verb: 'get',
  });

  public custodialLevels = new ApiCallLoader<ICustodialLevel>({
    url: 'api/v0/custodian/:site_id/scenarios/:scenario_id/levels',
    verb: 'get',
  });

  public custodialProfiles = new ApiCallLoader<ICustodialProfile>({
    url: 'api/v0/custodian/:site_id/scenarios/:scenario_id/profiles',
    verb: 'get',
    sort: (a, b) => a?.name.localeCompare(b?.name, 'en', { numeric: true }),
  });

  public custodialScenarios = new ApiCallLoader<ICustodialScenario>({
    url: 'api/v0/custodian/:site_id/scenarios',
    verb: 'get',
  });

  public allUsers = new ApiCallLoader<IClientUser>({
    url: 'api/v0/account/users',
    verb: 'get',
  });

  public custodialTasks = new ApiCallLoader<ICustodialTask>({
    url: 'api/v0/custodian/:site_id/scenarios/:scenario_id/tasks',
    verb: 'get',
  });

  async fetchSites() {
    const sites = await ApiCalls.Editor.GetEditorSites();
    this.sites = sites;
  }

  fetchUsers() {
    ApiCalls.Admin.GetUsers().then((IUsers) => {
      this.users = IUsers.map((x) => new User(x));
    });
  }

  getScenarios({ site_id }: { site_id: string }) {
    return new Promise<ICustodialScenario[]>((resolve, reject) => {
      if (site_id !== this.site_id) {
        this.scenarios = undefined;
        runInAction(() => {
          this.site_id = site_id;
        });

        return ApiCalls.Custodial.GetCustodialScenarios({ site_id })
          .then((scenarios) => {
            runInAction(() => {
              this.scenarios = scenarios.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }));
              resolve(this.scenarios);
            });
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      }
      resolve(this.scenarios ?? []);
    });
  }

  fetchBuildings({
    site_id,
    scenario_id,
  }: {
    site_id: string;
    scenario_id: string;
  }) {
    this.buildings = undefined;
    ApiCalls.Custodial.GetCustodialBuildings({ site_id, scenario_id })
      .then((buildings) => {
        runInAction(() => {
          this.buildings = buildings.sort(
            (a, b) => a.bldg.number
              ?.toString()
              .localeCompare(b.bldg.number ?? '', 'en', { numeric: true }),
          );
        });
      })
      .catch((err) => console.error(err));

    this.fetchBasics({ site_id, scenario_id }).then(() => {});
  }

  fetchCustodians({
    site_id,
    scenario_id,
  }: {
    site_id: string;
    scenario_id: string;
  }) {
    ApiCalls.Custodial.GetCustodialCustodians({ site_id, scenario_id }).then(
      (custodians) => {
        runInAction(() => {
          this.custodians = custodians
            .map((x) => new CustodialCustodian(x))
            .sort((a, b) => a.user.name.localeCompare(b.user.name, 'en', { numeric: true }));
        });
      },
    );
  }

  fetchCustodialScenarioTemplates({ site_id }: { site_id: string }) {
    ApiCalls.Custodial.GetCustodialTemplateScenarios({ site_id }).then(
      (templateHolder) => {
        runInAction(() => {
          this.templateScenarios = templateHolder;
        });
      },
    );
  }

  fetchSpaces({
    site_id,
    bldg_id,
    scenario_id,
  }: {
    site_id: string;
    bldg_id: string;
    scenario_id: string;
  }) {
    this.fetchBasics({ site_id, scenario_id }).then(() => {});

    this.spaces = undefined;
    return Promise.all([
      ApiCalls.Custodial.GetCustodialSpaces({ site_id, bldg_id, scenario_id }),
      ApiCalls.Editor.GetEditorSlabs({ site_id, bldg_id }),
      ApiCalls.Editor.GetEditorFloors({ site_id, bldg_id }),
    ])
      .then(([spaces, slabs, floors]) => {
        this.slabs = slabs;
        this.floors = floors;
        this.spaces = spaces;
      })
      .catch((err) => console.error(err));
  }

  fetchBasics({
    site_id,
    scenario_id,
  }: {
    site_id: string;
    scenario_id: string;
  }) {
    return new Promise<void>(async (resolve) => {
      if (this.site_id !== site_id || this.scenario_id !== scenario_id) {
        runInAction(() => {
          this.site_id = site_id;
          this.scenario_id = scenario_id;
        });

        await this.getScenarios({ site_id });

        scenario_id = scenario_id === 'default'
          ? this.scenarios?.find((x) => x.isDefault)?._id ?? ''
          : scenario_id;

        return Promise.all([
          this.fetchCustodialScenarioTemplates({ site_id }),
          this.getScenario({ site_id, scenario_id }),
          this.getProfiles({ site_id, scenario_id }),
          this.getZones({ site_id, scenario_id }),
          this.getLevels({ site_id, scenario_id }),
          this.getTeams({ site_id, scenario_id }),
          this.getTasks({ site_id, scenario_id }),
          this.getTaskFFE({ site_id, scenario_id }),
        ])
          .catch((err) => console.error(err))
          .finally(() => resolve());
      }
      resolve();
    });
  }

  setScenario({ scenario }: { scenario: ICustodialScenario }) {
    this.scenario = scenario;
  }

  getScenario({
    site_id,
    scenario_id,
  }: {
    site_id: string;
    scenario_id: string;
  }) {
    return ApiCalls.Custodial.GetCustodialScenario({
      site_id,
      scenario_id,
    }).then((scenario) => {
      runInAction(() => {
        this.scenario = {
          ...scenario,
          productiveMinutes: scenario.productiveMinutes ?? 420,
          productiveDays: scenario.productiveDays ?? 250,
        };
      });
    });
  }

  addScenario({ scenario }: { scenario: ICustodialScenario }) {
    this.scenarios = (this.scenarios ?? [])
      .concat([scenario])
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  removeScenario({ scenario }: { scenario: ICustodialScenario }) {
    this.scenarios = (this.scenarios ?? []).filter((x) => x !== scenario);
  }

  getProfiles({
    site_id,
    scenario_id,
  }: {
    site_id: string;
    scenario_id: string;
  }) {
    return ApiCalls.Custodial.GetCustodialProfiles({
      site_id,
      scenario_id,
    }).then((profiles) => {
      runInAction(() => {
        this.profiles = profiles.sort((a, b) => (a.name || '').localeCompare(b.name || '', 'en', { numeric: true }));
      });
    });
  }

  getZones({ site_id, scenario_id }: { site_id: string; scenario_id: string }) {
    return ApiCalls.Custodial.GetCustodialZones({ site_id, scenario_id }).then(
      (zones) => {
        runInAction(
          () => (this.zones = zones
            .map((x) => new CustodialZone(x))
            .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))),
        );
      },
    );
  }

  getLevels({
    site_id,
    scenario_id,
  }: {
    site_id: string;
    scenario_id: string;
  }) {
    return ApiCalls.Custodial.GetCustodialLevels({ site_id, scenario_id }).then(
      (levels) => {
        runInAction(() => {
          this.levels = levels;
        });
      },
    );
  }

  getTeams({ site_id, scenario_id }: { site_id: string; scenario_id: string }) {
    return ApiCalls.Custodial.GetCustodialTeams({ site_id, scenario_id }).then(
      (teams) => {
        runInAction(() => {
          this.teams = teams.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }));
        });
      },
    );
  }

  getTasks({ site_id, scenario_id }: { site_id: string; scenario_id: string }) {
    return ApiCalls.Custodial.GetCustodialTasks({ site_id, scenario_id }).then(
      (tasks) => {
        runInAction(() => {
          this.tasks = tasks.sort((a, b) => (a.name || '').localeCompare(b.name || '', 'en', { numeric: true }));
        });
      },
    );
  }

  getTaskFFE({
    site_id,
    scenario_id,
  }: {
    site_id: string;
    scenario_id: string;
  }) {
    return ApiCalls.Custodial.GetCustodialTaskFFE({
      site_id,
      scenario_id,
    }).then((tasksFFE) => {
      runInAction(() => {
        this.tasksFFE = tasksFFE.sort((a, b) => (a.name || '').localeCompare(b.name || '', 'en', { numeric: true }));
      });
    });
  }

  // Update the space list with a small subset of changed rooms
  replaceSpaces({ spaces }: { spaces: ICustodialSpaceClient[] }) {
    const space_ids = spaces.map((val) => val._id);
    this.spaces = (this.spaces || [])
      .slice()
      .filter((val) => space_ids.indexOf(val._id) === -1)
      .concat(spaces);
    return this.spaces;
  }

  // Update the space list with a small subset of changed rooms
  replaceCustodians({ custodians }: { custodians: ICustodialCustodian[] }) {
    const custodian_ids = custodians.map((val) => val._id);
    this.custodians = (this.custodians || [])
      .slice()
      .filter((val) => custodian_ids.indexOf(val._id) === -1)
      .concat(custodians.map((x) => new CustodialCustodian(x)));
    return this.custodians;
  }

  // Update the custodian list by removing custodians.
  removeCustodians({ custodians }: { custodians: ICustodialCustodian[] }) {
    const custodian_ids = custodians.map((val) => val._id);
    this.custodians = (this.custodians || []).filter(
      (val) => custodian_ids.indexOf(val._id) === -1,
    );
    return this.custodians;
  }

  // Update the zone list with a subset of changed zones
  replaceZones({ zones }: { zones: ICustodialZone[] }) {
    const zone_ids = zones.map((val) => val._id);
    this.zones = (this.zones || [])
      .slice()
      .filter((val) => zone_ids.indexOf(val._id) === -1)
      .concat(zones.map((x) => new CustodialZone(x)));
    return this.zones;
  }

  // Update the zone list with a subset of changed zones
  replaceScenarios({ scenarios }: { scenarios: ICustodialScenario[] }) {
    const _ids = scenarios.map((val) => val._id);
    this.scenarios = (this.scenarios || [])
      .slice()
      .filter((val) => _ids.indexOf(val._id) === -1)
      .concat(scenarios);
    return this.scenarios;
  }

  // Update the zone list with a subset of changed zones
  replaceTeams({ teams }: { teams: ICustodialTeam[] }) {
    const _ids = teams.map((val) => val._id);
    this.teams = (this.teams || [])
      .slice()
      .filter((val) => _ids.indexOf(val._id) === -1)
      .concat(teams)
      .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }));
    return this.teams;
  }

  // Update the zone list by removing zones.
  removeZones({ zones }: { zones: ICustodialZone[] }) {
    const zone_ids = zones.map((val) => val._id);
    this.zones = (this.zones || []).filter(
      (val) => zone_ids.indexOf(val._id) === -1,
    );
    return this.custodians;
  }

  get zonesHash() {
    return this.zones.reduce((acc: { [key: string]: ICustodialZone }, val) => {
      acc[val._id] = val;
      return acc;
    }, {});
  }

  get levelsHash() {
    return this.levels.reduce(
      (acc: { [key: string]: ICustodialLevel }, val) => {
        acc[val._id] = val;
        return acc;
      },
      {},
    );
  }

  get profilesHash() {
    return this.profiles.reduce(
      (acc: { [key: string]: ICustodialProfile }, val) => {
        acc[val._id] = val;
        return acc;
      },
      {},
    );
  }
}

export const custodialAppState = new CustodialAppState();
