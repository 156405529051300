import { useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { useMemo } from 'react';

dayjs.extend(isoWeek); // use plugin

export function useDateRange() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { startDate, endDate } = useMemo(() => {
    let defaultStartDate = dayjs().startOf('isoWeek'); // Monday of this week
    let defaultEndDate = dayjs().startOf('isoWeek').add(6, 'day'); // Friday of this week

    defaultStartDate = dayjs(
      searchParams.get('startDate') ?? defaultStartDate.format('YYYYMMDD'),
      'YYYYMMDD',
    );

    defaultEndDate = dayjs(
      searchParams.get('endDate') ?? defaultEndDate.format('YYYYMMDD'),
      'YYYYMMDD',
    );
    return { startDate: defaultStartDate, endDate: defaultEndDate };
  }, [searchParams]);

  function set({ startDate, endDate }: { startDate?: Dayjs | null; endDate?: Dayjs | null;}) {
    if (startDate) searchParams.set('startDate', startDate.format('YYYYMMDD'));
    if (endDate) searchParams.set('endDate', endDate.format('YYYYMMDD'));
    setSearchParams(searchParams);
  }

  return {
    data: {
      startDate,
      endDate,
    },
    stringData: {
      startDate: startDate.format('YYYYMMDD'),
      endDate: endDate.format('YYYYMMDD'),
    },
    set,
  };
}
