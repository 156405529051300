import PublicIcon from '@mui/icons-material/Public';
import { useEditorSites } from 'client/modules/editor/Utilities/Hooks';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import { InspectionIcon } from 'client/components/CustomIcons//InspectionsIcon';
import { ReportsIcon } from 'client/components/CustomIcons//ReportsIcon';
import { BuildingsIcon } from 'client/components/CustomIcons//BuildingsIcon';
import { RoomsIcon } from 'client/components/CustomIcons//RoomsIcon';
import { SettingsIcon } from 'client/components/CustomIcons//SettingsIcon';
import { ToolboxIcon } from 'client/components/CustomIcons//ToolboxIcon';
import { BaseModule } from './base';

export class ModuleEditor extends BaseModule {
  constructor() {
    super({
      name: 'DabbleEDITOR',
      shortName: 'EDITOR',
      baseRoute: 'editor',
      icon: DesignServicesIcon,
      textColor: '#f8f8f8',
      secondaryTextColor: '#fff',
      secondaryColor: '#4a4bff',
      backgroundColor: '#151ca1',
      navButtons: [
        {
          type: 'site',
          text: 'Sites',
          icon: PublicIcon,
          route: '/editor/sites',
          quickPickType: 'site',
          matchRoutes: ['/editor/sites/:site_id', '/editor/sites'],
        },
        {
          type: 'nav',
          text: 'Buildings',
          icon: BuildingsIcon,
          route: '/editor/sites/:site_id/buildings',
          requiredParams: ['site_id'],
        },
        {
          type: 'nav',
          text: 'Rooms',
          icon: RoomsIcon,
          route: '/editor/sites/:site_id/buildings/:bldg_id/details',
          matchRoutes: [
            '/editor/sites/:site_id/buildings/:bldg_id/details/:tabName',
            '/editor/sites/:site_id/buildings/:bldg_id/spaces/:space_id/details',
          ],
          requiredParams: ['bldg_id'],
        },
        // {type: 'date', text: 'Date', icon: InspectionIcon, route: ''},
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'Toolbox',
          icon: ToolboxIcon,
          route: '/editor/sites/:site_id/toolbox/main',
          requiredParams: ['site_id'],
        },
        {
          type: 'nav',
          text: 'Reports',
          icon: ReportsIcon,
          route: '/editor/sites/:site_id/reports/main',
          requiredParams: ['site_id', 'ALWAYS_DISABLED'],
        },
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'Settings',
          icon: SettingsIcon,
          route: '/editor/sites/:site_id/settings',
          matchRoutes: [
            '/editor/sites/:site_id/settings/list',
            '/editor/sites/:site_id/settings/roomUses',
            '/editor/sites/:site_id/settings/flooring',
            '/editor/sites/:site_id/settings/banner',
            '/editor/sites/:site_id/settings/onuma',
            '/editor/sites/:site_id/settings/series25',
            '/editor/sites/:site_id/settings/series25/:tabName',
            '/editor/sites/:site_id/settings/series25/:tabName/:bldg_id',
            '/editor/sites/:site_id/settings/ethos',
            '/editor/sites/:site_id/settings/cleanOps',
            '/editor/sites/:site_id/settings/autodesk',
            '/editor/sites/:site_id/settings/sftp',
          ],
          requiredParams: ['site_id'],
        },
      ],
    });
  }

  protected setup() {
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }
}
