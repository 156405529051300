import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserMutation } from 'client/utilities/hooks';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useConfirm } from 'material-ui-confirm';
import { LoginInput } from './loginInput';

export const SetPassword = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { mutation } = useUserMutation();
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const confirm = useConfirm();

  function handleSubmit(e: any) {
    e.preventDefault();
    if (password.length < 8) {
      confirm({ description: t('Password must be at least 8 characters long.') });
    } else {
      mutation
        .mutateAsync({ method: 'setPassword', token: searchParams.get('token') || '', password })
        .then(() => navigate('/'));
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <LoginInput
          type="password"
          handleChange={setPassword}
          name="password"
          label="Password"
          value={password}
          helperText={t('Password must be at least 8 characters')}
        />
        <div style={{ textAlign: 'center' }}>
          <button className="btn btn-default" type="submit">
            Set Password
          </button>
        </div>
        <small>
          <a href="/">
            {t('Sign In?')}
          </a>
        </small>
      </form>
    </div>
  );
};
