import { useIsMobile } from 'client/utilities/hooks/useIsMobile';
import { Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useSessionStorage } from 'usehooks-ts';

export const MobileRedirection = ({ mobile, desktop }: { mobile: string, desktop: string }) => {
  const isMobile = useIsMobile();

  const [open, setOpen] = useSessionStorage('navOpen', true);

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);

  return (<Navigate to={isMobile ? mobile : desktop} replace />);
};
