import { FC, ReactNode } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';

export interface IBaseModule {
  name: string;
  icon?: FC;
  shortName: string;
  baseRoute: string;
  navButtons: IModuleLeftNavButtons[];
  textColor: string;
  secondaryTextColor: string;
  secondaryColor: string;
  backgroundColor: string;
  allowLeftNavHelper?: boolean;
}

export interface IModuleLeftNavButtons {
  type: 'site' | 'scenario' | 'date' | 'spacer' | 'nav' | 'gap';
  text: string;
  node?: ReactNode;
  icon: FC;
  route: string;
  matchRoutes?: string[];
  requiredParams?: string[];
  basePage?: string;
  quickPickType?: 'site' | 'scenario' | 'building' | 'space'
}

export abstract class BaseModule {
  public readonly name: string;

  public readonly icon?: FC;

  public readonly shortName: string;

  public readonly baseRoute: string;

  public readonly navButtons: IModuleLeftNavButtons[];

  public readonly textColor: string;

  public readonly secondaryTextColor: string;

  public readonly secondaryColor: string;

  public readonly backgroundColor: string;

  public readonly allowLeftNavHelper: boolean;

  private loaded: boolean = false;

  constructor({
    name,
    icon,
    shortName,
    baseRoute,
    navButtons,
    textColor,
    secondaryTextColor,
    secondaryColor,
    backgroundColor,
    allowLeftNavHelper,
  }: IBaseModule) {
    this.name = name;
    this.icon = icon;
    this.shortName = shortName;
    this.baseRoute = baseRoute;
    this.navButtons = navButtons.map((val) => ({
      ...val,
      basePage: val.route.split('/').slice(1, 3).join('/'),
    }));
    this.textColor = textColor;
    this.secondaryTextColor = secondaryTextColor;
    this.secondaryColor = secondaryColor;
    this.backgroundColor = backgroundColor;
    this.allowLeftNavHelper = allowLeftNavHelper ?? true;
  }

  public hasPage(pageName?: string): boolean {
    return !!(
      pageName
      && this.navButtons.find((nav) => nav.route.indexOf(pageName) > -1)
    );
  }

  protected setLoaded(state: boolean) {
    this.loaded = state;
  }

  public initialize() {
    if (!this.loaded) {
      this.setup().then(() => {
        this.setLoaded(true);
      });
    }
  }

  public get permissionsName() {
    return this.name.replace('Dabble', 'dabble');
  }

  protected abstract setup(): Promise<any>;
}
