export interface IEditorFlooring {
  _id: string;
  name: string;
  color: string;
  siteType?: 'exterior' | 'interior'
}

export class EditorFlooring {
  public readonly id: string;

  public name: string;

  public color: string;

  constructor({ _id, name, color }: IEditorFlooring) {
    this.id = _id;
    this.name = name;
    this.color = color;
  }

  public get label() {
    return this.name;
  }

  public get value() {
    return this.id;
  }
}
