import React from 'react';
import { Button } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';

interface IEditButton {
  isEditor: boolean;
  onClick: () => void;
  fullWidth?: boolean;
  text: string;
  style?: { [key: string]: string };
  autoFocus?: boolean;
  disabled?: boolean;
  confirmText?: string;
  confirmPromptResponse?: string;
}

export const DeleteButton = ({
  isEditor,
  onClick,
  fullWidth,
  text,
  style,
  autoFocus,
  disabled,
  confirmText,
  confirmPromptResponse,
}: IEditButton) => {
  const confirm = useConfirm();

  function onClickConfirm() {
    if (confirmPromptResponse && confirmText) {
      confirm({
        description: confirmText ?? 'Are you sure you want to do this action?',
        confirmationKeyword: confirmPromptResponse,
      }).then(() => onClick());
    } else {
      confirm({
        description: confirmText ?? 'Are you sure you want to do this action?',
      }).then(() => onClick());
    }
  }

  return (
    <Button
      disabled={disabled}
      autoFocus={autoFocus}
      size="small"
      onClick={onClickConfirm}
      color="error"
      style={{
        // color: 'white',
        display: isEditor ? '' : 'none',
        width: fullWidth ? '100%' : undefined,
        // backgroundColor: 'red',
        ...(style || {}),
      }}
      variant="contained"
    >
      {text}
    </Button>
  );
};
