import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { InspectionIcon } from 'client/components/CustomIcons//InspectionsIcon';
import { UsersIcon } from 'client/components/CustomIcons//UsersIcon';
import { BaseModule } from './base';

export class ModuleEntrance extends BaseModule {
  constructor() {
    super({
      name: 'Modules',
      shortName: 'Modules',
      icon: ViewModuleIcon,
      baseRoute: '',
      textColor: '#f8f8f8',
      secondaryTextColor: '#fff',
      secondaryColor: '#c91e00',
      backgroundColor: '#650000',
      navButtons: [
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'News',
          icon: UsersIcon,
          route: '/',
        },
      ],
      allowLeftNavHelper: false,
    });
  }

  protected setup() {
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }
}
