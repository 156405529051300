import React from 'react';

// @ts-ignore
export const ZonesIcon = () => (
  <svg
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 426.667 426.667"
    height="100%"
    width="100%"
  >
    <title id="zonesIconTitle">Zones Icon</title>
    <desc id="zonesIconDesc">Zones Icon</desc>

    <polygon
      points="320,0 277.333,0 277.333,89.6 213.333,153.6 149.333,89.6 149.333,0 106.667,0 106.667,106.667 0,106.667
				0,149.333 89.6,149.333 153.6,213.333 89.6,277.333 0,277.333 0,320 106.667,320 106.667,426.667 149.333,426.667
				149.333,337.067 213.333,273.067 277.333,337.067 277.333,426.667 320,426.667 320,320 426.667,320 426.667,277.333
				337.067,277.333 273.067,213.333 337.067,149.333 426.667,149.333 426.667,106.667 320,106.667 			"
    />
    <circle cx="394.667" cy="32" r="32" />
    <circle cx="32" cy="32" r="32" />
    <circle cx="394.667" cy="394.667" r="32" />
    <circle cx="32" cy="394.667" r="32" />
  </svg>
);
