import React, { ReactElement, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  DataGridPro,
  GridCallbackDetails,
  GridColDef,
  GridDensity,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowModesModel,
  GridRowParams,
  GridState,
} from '@mui/x-data-grid-pro';
import { DataGridProProps } from '@mui/x-data-grid-pro/models/dataGridProProps';
import { EditButton } from 'client/components/generic/EditButton';
import { useTranslation } from 'react-i18next';
import {
  Button, IconButton, Menu, MenuItem,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useSessionStorage } from 'usehooks-ts';
import { GridColType } from '@mui/x-data-grid/models/colDef/gridColType';
import { useNavigate } from 'react-router';
import { useDfGridColumns } from 'client/components/generic/Utilities/useDfGridColumns';
import xlsx from '@sheet/dabblefox';

export type IDfGridColDef = GridColDef & {
  type?: GridColType | 'color';
  size?: 'small' | 'medium' | 'large';
};

interface IDefaultTable extends Partial<DataGridProProps> {
  columns: IDfGridColDef[];
  data: any[];
  addingLabel?: string;
  tableID?: string;
  loading?: boolean;
  density?: GridDensity;
  selected_ids?: string[];
  setSelected_ids?: (selected: string[]) => void;
  setSortedData?: (sortedData: any[]) => void;
  getRowId?: (row: any) => string;
  allowExport?: boolean;
  rowReordering?: boolean;
  buttons?: ReactElement;
  getTreeDataPath?: (row: any) => string[];
  editSelected?: () => void;
  copySelected?: () => void;
  setAdding?: () => void;
  reordering?: boolean;
  setReordering?: () => void;
  saveReordering?: () => void;
  cancelReordering?: () => void;
  ignoreHeight?: boolean;
  fixedHeight?: number;
  processRowUpdate?: (updatedRow: any, originalRow: any) => void;
  processRowRemove?: (row?: any) => void;
  isEditor?: boolean;
  setRowModesModel?: (rowModesModel: GridRowModesModel) => void;
  onRowClickNavigate?: ({ row }: { row?: any }) => string | undefined;
  onRowClickEdit?: ({ row }: { row?: any }) => void;
  onRowClickDelete?: (row?: any) => void;
}

const TableMenu = ({ tableID }: { tableID: string }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [density, setDensity] = useSessionStorage<GridDensity>(
    'density',
    'standard',
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick} title={t('Table Options')}>
        <MenuIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem onClick={() => setDensity('compact')}>
          {t('Compact')}
        </MenuItem>
        <MenuItem onClick={() => setDensity('standard')}>
          {t('Normal')}
        </MenuItem>
      </Menu>
    </div>
  );
};

export const DfDataGrid = observer(
  (props: IDefaultTable & Partial<DataGridProProps>) => {
    const {
      isEditor,
      processRowUpdate,
      columns,
      data,
      processRowRemove,
      onRowClickEdit,
      onRowClickDelete,
      onRowClickNavigate,
    } = props;
    const { t } = useTranslation();
    const [tableHeight, setTableHeight] = useState<number>(500);
    const [density] = useSessionStorage<GridDensity>('density', 'standard');
    const [rowModesModel, setRowModesModelState] = React.useState<GridRowModesModel>({});
    const [gridState, setGridState] = useSessionStorage<
      GridState | Record<string, never>
    >(`gridState_${props.tableID ?? 'defaultTable'}`, {});
    const navigate = useNavigate();

    function setRowModesModel(newRowModesModel: GridRowModesModel) {
      setRowModesModelState(newRowModesModel);
      props.setRowModesModel?.(newRowModesModel);
    }

    useEffect(() => {
      const mapHeight = window.innerHeight
        - (document.getElementById('tabletop')!.offsetTop + 10);
      setTableHeight(mapHeight);
    }, [window.innerHeight]);

    const parsedColumns = useDfGridColumns({
      t,
      columns,
      isEditor,
      data,
      processRowUpdate,
      processRowRemove,
      onRowClickEdit,
      onRowClickDelete,
      onRowClickNavigate,
      rowModesModel,
      setRowModesModel,
    });

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (
      params,
      event,
    ) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    };

    function onRowClick(
      params: GridRowParams<any>,
      event: any | undefined,
      details: GridCallbackDetails<any>,
    ) {
      if (props.onRowClickNavigate) {
        const url = props.onRowClickNavigate({ row: params.row });
        if (url?.startsWith('http')) window.location.href = url;
        if (url) navigate(url);
      } else if (props.onRowClickEdit) {
        props.onRowClickEdit({ row: params.row });
      } else if (props.onRowClickDelete) {
        props.onRowClickDelete({ row: params.row });
      } else if (props.onRowClick) {
        props.onRowClick(params, event, details);
      }
    }

    function exportToExcel() {
      const wb = xlsx.utils.book_new();

      const sheetName = props.tableID ?? 'Export';
      wb.Props = {
        Title: sheetName,
        Author: 'Dabblefox',
        CreatedDate: new Date(),
      };

      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = xlsx.utils.json_to_sheet(
        props.data.map((singleData) => {
          const returnData: { [key: string]: string | number | undefined } = {
            _id: singleData._id,
          };
          columns
            .filter((x) => x.type !== 'actions')
            .forEach((column) => {
              if (column.valueGetter) {
                // @ts-expect-error
                returnData[column.headerName!] = column.valueGetter(singleData[column.field], singleData);
              } else {
                returnData[column.headerName!] = singleData[column.field];
              }
            });
          return returnData;
        }),
      );
      xlsx.writeFile(wb, `${sheetName}.xlsx`, { cellStyles: true });
    }

    return (
      <div style={{ flex: 1, minWidth: 0 }}>
        <div
          style={{
            display: 'flex',
            gap: '.5em',
            justifyContent: 'end',
            padding: '.5em',
            boxSizing: 'border-box',
          }}
        >
          {props.editSelected && (
            <EditButton
              disabled={!props.selected_ids?.length}
              isEditor={!!props.isEditor}
              onClick={props.editSelected}
              text={t('Edit')}
            />
          )}
          {props.copySelected && (
            <EditButton
              disabled={!props.selected_ids?.length}
              isEditor={!!props.isEditor}
              onClick={props.copySelected}
              text={t('Copy')}
            />
          )}
          {props.setAdding && (
            <EditButton
              isEditor={!!props.isEditor}
              onClick={props.setAdding}
              text={props.addingLabel ?? t('Add')}
            />
          )}
          {props.setReordering && (
            <EditButton
              isEditor={!!props.isEditor}
              isEditing={props.rowReordering}
              onClick={props.setReordering}
              saveChanges={props.saveReordering}
              cancelChanges={props.cancelReordering}
              text={t('Reorder')}
            />
          )}
          <div style={{ flex: 1 }} />
          {props.buttons}
          {props.allowExport && (
            <Button
              onClick={() => exportToExcel()}
            >
              {t('Export')}
            </Button>
          )}
          <TableMenu tableID={props.tableID ?? 'defaultTable'} />
        </div>
        <div
          id="tabletop"
          style={{
            flex: 1,
            minWidth: 0,
            height: props.ignoreHeight ? undefined : `${props.fixedHeight ?? tableHeight}px`,
          }}
        >
          <DataGridPro
            autoPageSize
            {...props}
            // initialState={{
            //   ...gridState,
            // }}
            hideFooter
            loading={!!props.loading}
            checkboxSelection={!!props.setSelected_ids}
            rowSelectionModel={props?.selected_ids}
            onRowSelectionModelChange={(newSelection) => {
              if (props.setSelected_ids) props?.setSelected_ids(newSelection as string[]);
            }}
            disableRowSelectionOnClick
            density={density ?? props?.density ?? 'standard'}
            columns={parsedColumns}
            rows={props.data ?? []}
            getRowId={(row) => (props.getRowId ? props.getRowId(row) : row._id)}
            onRowClick={onRowClick}
            getRowClassName={(params) => (props.onRowClick || props.onRowClickNavigate ? 'clickable' : '')}
            // onStateChange={(state) => {
            //   setGridState({ ...state, sorting: undefined });
            // }}
            getTreeDataPath={props.getTreeDataPath}
            treeData={!!props.getTreeDataPath}
            rowModesModel={rowModesModel}
            // cellModesModel={{}}
            onRowEditStop={handleRowEditStop}
          />
        </div>
      </div>
    );
  },
);
