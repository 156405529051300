import axios, { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { ApiCalls } from '../../Analyze/Utilities/ApiCalls';

// @ts-ignore
const { VITE_API } = import.meta.env;
axios.defaults.baseURL = VITE_API;

export class QueryFunctions {
  static SimpleGet<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      axios
        .get(`/${url}`, {
          ...config,
          headers: { Authorization: `Basic ${ApiCalls.GetToken()}` },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static SimplePost<T>(url: string, body?: any): Promise<T> {
    return new Promise((resolve, reject) => {
      axios
        .post(`/${url}`, body, {
          headers: { Authorization: `Basic ${ApiCalls.GetToken()}` },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          toast.error('Error creating data');
          reject(err);
        });
    });
  }

  static SimplePatch<T>(url: string, body?: any): Promise<T> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/${url}`, body, {
          headers: { Authorization: `Basic ${ApiCalls.GetToken()}` },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          toast.error('Error updating data');
          reject(err);
        });
    });
  }

  static SimplePut<T>(url: string, body?: any): Promise<T> {
    return new Promise((resolve, reject) => {
      axios
        .put(`/${url}`, body, {
          headers: { Authorization: `Basic ${ApiCalls.GetToken()}` },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          toast.error('Error uploading data');
          reject(err);
        });
    });
  }

  static SimpleDelete(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${url}`, {
          headers: { Authorization: `Basic ${ApiCalls.GetToken()}` },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
