export class ErrorState {
  errors: DfError[] = [];

  addError(message: string) {
    this.errors = this.errors.concat([new DfError(message, this)]);
  }

  removeError(error: DfError) {
    this.errors = this.errors.filter((err) => err !== error);
  }
}

export class DfError {
  public readonly message: string;

  private readonly errorState: ErrorState;

  constructor(message: string, errorState: ErrorState) {
    this.message = message;
    this.errorState = errorState;
  }

  remove() {
    console.log('Remove');
    this.errorState.removeError(this);
  }
}
