import { ISpace, Space } from './space';
import ApiCalls from '../../apiCalls';
import { Site } from './site';
import { ISlab } from './slab';
import { IFloor } from './floor';

export interface IBuilding {
  _id: string;
  number: number | string;
  name: string;
  site: Site;
}

export class Building {
  public readonly _id: string;

  public readonly _site: Site;

  private _name: string;

  private _number: string;

  private _spaces: Space[] = [];

  private _slabs: ISlab[] = [];

  private _floors: IFloor[] = [];

  public floorIndex: number = 0;

  public ready: boolean = false;

  constructor({
    _id, number, name, site,
  }: IBuilding) {
    this._id = _id;
    this._site = site;
    this._number = number ? number.toString() : '';
    this._name = name;
  }

  public interface() {
    return {
      _id: this.id,
      number: this.number,
      name: this.name,
    };
  }

  fetchSpaces() {
    return Promise.all([
      ApiCalls.getSpaces({ site_id: this._site.id, bldg_id: this.id }),
      ApiCalls.getSlabs({ site_id: this._site.id, bldg_id: this.id }),
      ApiCalls.getFloors({ site_id: this._site.id, bldg_id: this.id }),
    ])
      .then(async ([spaces, slabs, floors]) => {
        await this.setSpaces(
          spaces.map(
            (space) => new Space({ ...(space as ISpace), bldg: this }),
          ),
        );
        await this.setSlabs(slabs);
        await this.setFloors(floors);
      })
      .finally(() => {
        this.setReady(true);
      });
  }

  public getSpaceById(space_id: string): Promise<Space | undefined> {
    return new Promise((resolve, reject) => {
      if (this.ready) {
        resolve(this.spaces.find((space) => space.id === space_id));
      } else {
        this.fetchSpaces()
          .then(() => {
            resolve(this.spaces.find((space) => space.id === space_id));
          })
          .catch((err) => reject(err));
      }
    });
  }

  setReady(state: boolean) {
    this.ready = state;
  }

  setSlabs(slabs: ISlab[]) {
    this._slabs = slabs;
  }

  setSpaces(spaces: Space[]) {
    this._spaces = spaces.sort((a, b) => a.number.localeCompare(b.number, 'en', { numeric: true }));
  }

  setFloors(floors: IFloor[]) {
    this._floors = floors.sort((a, b) => a.floorIndex - b.floorIndex);
  }

  setFloorIndex(floorIndex: number) {
    this.floorIndex = floorIndex;
  }

  selectSingleSpace(space: Space, e: any) {
    if (e?.originalEvent?.ctrlKey) {
      space.toggleSelected();
    } else if (this.selectedSpaces.length > 1 && space.selected) {
      this.selectedSpaces.forEach((space) => space.toggleSelected());
      space.setSelected(true);
    } else {
      this.selectedSpaces
        .filter((val) => val !== space)
        .forEach((space) => space.toggleSelected());
      space.toggleSelected();
    }
  }

  public get id() {
    return this._id;
  }

  public get number() {
    return this._number;
  }

  public get name() {
    return this._name;
  }

  public get spaces() {
    return this._spaces;
  }

  public get slabs() {
    return this._slabs;
  }

  public get floors() {
    return this._floors;
  }
  //
  // public get site() {
  //     return this._site
  // }

  public get selectedSpaces() {
    return this.spaces.filter((space) => space.selected);
  }

  public get label() {
    return [this.number, this.name].filter((val) => val).join(' - ');
  }

  public get value() {
    return this.id;
  }

  // public get qc() {
  //     return this._site.qcState.buildingsHash[this.id]
  // }
}
