import React from 'react';

// @ts-ignore
export const InspectionIcon = () => (
  <svg
    id="Layer_5"
    enableBackground="new 0 0 64 64"
    height="100%"
    viewBox="0 0 64 64"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="inspectionIconTitle">Inspection Icon</title>
    <desc id="inspectionIconDesc">Inspection Icon</desc>

    <path
      d="m60 16v-6h-2v6h-2v-2h-2v2h-2v-6h-2v6h-2v-4h-2v4h-1.551l-8.43-9.483-3.447 3.676c.299.683.549 1.391.757 2.117l2.651-2.827 7.571 8.517h18.449v-2z"
    />
    <path
      d="m32 17c0-8.271-6.729-15-15-15s-15 6.729-15 15 6.729 15 15 15 15-6.729 15-15zm-28 0c0-7.168 5.832-13 13-13s13 5.832 13 13-5.832 13-13 13-13-5.832-13-13z"
    />
    <path
      d="m19 40h-4c-.552 0-1 .448-1 1v16c0 1.654 1.346 3 3 3s3-1.346 3-3v-16c0-.552-.448-1-1-1zm-1 18h-2v-2h2zm0-4h-2v-2h2zm0-4h-2v-2h2z"
    />
    <path
      d="m27.947 18.053-6.923 7.385-5.438-5.438h-5.586v-2h6.414l4.562 4.562 6.869-7.326c-.848-5.228-5.381-9.236-10.845-9.236-6.065 0-11 4.935-11 11s4.935 11 11 11c5.71 0 10.415-4.374 10.947-9.947zm-3.947-4.053h2v2h-2zm-4 0h2v2h-2zm-4 0h2v2h-2zm-4 0h2v2h-2zm-4 2v-2h2v2z"
    />
    <path d="m15 33.872v4.128h4v-4.128c-.657.077-1.322.128-2 .128s-1.343-.051-2-.128z" />
    <path d="m54 60h-2v-28h-8v28h-2v-24h-8v24h-2v-20h-8v22h38v-36h-8z" />
  </svg>
);
