import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useUser } from 'client/utilities/hooks';
import { MobileRedirection } from 'client/components/MobileRedirection';
import { LoginPage } from 'client/components/login/login';
import { Loading } from 'client/components/generic';
import { NoServicePage } from 'client/components/login/noService';
import { SetPassword } from 'client/components/login/setPassword';
import { LoginWrapper } from './components/login/loginWrapper';

const GeneralRouter = React.lazy(
  () => import('./modules/general/generalRouter'),
);
const CustodianRouter = React.lazy(
  () => import('./modules/custodian/CustodianRouter'),
);
const GroundsRouter = React.lazy(
  () => import('./modules/grounds/GroundsRouter'),
);
const QcRouter = React.lazy(() => import('./modules/qc/qcRouter'));
const AdminRouter = React.lazy(() => import('./modules/admin/AdminRouter'));
const SpaceRouter = React.lazy(() => import('./modules/space/SpaceRouter'));
const Shelf_ClientRouter = React.lazy(() => import('./modules/shelf/Shelf_ClientRouter'));
const SuperAdminRouter = React.lazy(
  () => import('./modules/superAdmin/SuperAdminRouter'),
);
const MobileRouter = React.lazy(() => import('./modules/mobile/MobileRouter'));
const EditorRouter = React.lazy(() => import('./modules/editor/EditorRouter'));

export const AppRouter = () => {
  const { isLoading, connectedButNotAuthorized, notConnected } = useUser();

  if (isLoading) {
    return <Loading />;
  }
  if (notConnected) {
    return <NoServicePage />;
  }
  if (connectedButNotAuthorized) {
    return (
      <Routes>
        <Route path="/forgotPassword" element={<LoginPage />} />
        <Route path="/setPassword" element={<LoginWrapper><SetPassword /></LoginWrapper>} />
        <Route path="*" element={<LoginPage />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<MobileRedirection mobile="/mobile" desktop="/general" />} />
      <Route path={'/qc/*'} element={<QcRouter />} />
      <Route path={'/editor/*'} element={<EditorRouter />} />
      <Route path={'/admin/*'} element={<AdminRouter />} />
      <Route path={'/shelf/*'} element={<Shelf_ClientRouter />} />
      <Route path={'/space/*'} element={<SpaceRouter />} />
      <Route path={'/custodian/*'} element={<CustodianRouter />} />
      <Route path={'/grounds/*'} element={<GroundsRouter />} />
      <Route path={'/superAdmin/*'} element={<SuperAdminRouter />} />
      <Route path={'/mobile/*'} element={<MobileRouter />} />
      <Route path="*" element={<GeneralRouter />} />
    </Routes>
  );
};
