import { useState } from 'react';
import {
  MutationFunction, QueryFunction, useMutation, useQuery, useQueryClient,
} from '@tanstack/react-query';
import { QueryFunctions } from 'client/utilities/functions';
import { User } from 'client/utilities/classes/auth/user';
import { IClientUser, IPatchUser } from 'shared/interfaces/Admin';
import ApiCalls from '../apiCalls';

export function useUser() {
  const [connectedButNotAuthorized, setConnectedButNotAuthorized] = useState(false);
  const [notConnected, setNotConnected] = useState(false);

  const queryFn: QueryFunction<any, string[]> = ({
    queryKey: [key],
  }: {
    queryKey: string[];
  }) => QueryFunctions.SimpleGet<IClientUser>('api/v0/account/auth/user').then(
    (results) => new User({ ...results, site_id: '' }),
  );

  const {
    data, refetch, isLoading, isFetching,
  } = useQuery({
    queryKey: ['auth/user'],
    queryFn,
    retry: (failureCount: number, err: any) => {
      if (!err.response) {
        setNotConnected(true);
        return true;
      }
      if (err.response.status === 401) {
        setConnectedButNotAuthorized(true);
        return false;
      }
      return failureCount < 3;
    },
    refetchOnWindowFocus: false,
  });

  return {
    notConnected,
    connectedButNotAuthorized,
    data,
    isLoading,
    isFetching,
  };
}

export const useUserMutation = () => {
  const queryClient = useQueryClient();

  const mutationFn: MutationFunction<
    void,
    {
      method: 'login' | 'logout' | 'patch' | 'resetPassword' | 'setPassword';
      email?: string;
      password?: string;
      token?: string;
      user?: Partial<IPatchUser & { password: string }>;
    }
  > = ({
    method, email, password, user, token,
  }) => {
    switch (method) {
      case 'login':
        return QueryFunctions.SimplePost<{ token: string }>(
          'api/v0/account/auth/login',
          { email, password },
        ).then(({ token }) => {
          console.log('TOKEN', token);
          if (!token) throw new Error('No token returned');
          ApiCalls.setToken({ token });
        });
      case 'logout':
        return QueryFunctions.SimplePost<void>('api/v0/account/auth/logout');
      case 'resetPassword':
        return QueryFunctions.SimplePost<void>(
          'api/v0/account/auth/requestReset',
          { email },
        );
      case 'setPassword':
        return QueryFunctions.SimplePost<void>(
          'api/v0/account/auth/resetByToken',
          { token, password },
        );
      case 'patch':
        return user
          ? QueryFunctions.SimplePatch<void>(
            `api/v0/account/users/${user._id}`,
            user,
          )
          : Promise.reject(new Error('No user provided'));
      default:
        return Promise.reject(new Error('Method not implemented'));
    }
  };

  const mutation = useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['auth/user'] });
    },
  });

  return { mutation };
};
