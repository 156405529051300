import React from 'react';

// @ts-ignore
export const BuildingsIcon = () => (
  <svg
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 484.738 484.738"
  >
    <title id="buildingsIconTitle">Buildings Icon</title>
    <desc id="buildingsIconDesc">Buildings Icon</desc>

    <path
      d="M131.012,438.372h85.624v-87.557h51.467v87.557h85.624V46.366H131.012V438.372z M257.993,92.564h51.466v51.467h-51.466
			V92.564z M257.993,175.279h51.466v51.467h-51.466V175.279z M257.993,257.992h51.466v51.467h-51.466V257.992z M175.279,92.564
			h51.466v51.467h-51.466V92.564z M175.279,175.279h51.466v51.467h-51.466V175.279z M175.279,257.992h51.466v51.467h-51.466V257.992
			z"
    />
    <path
      d="M373.719,81.482V358.66h33.065v79.712h77.954V81.482H373.719z M444.437,321.009H397.58v-46.856h46.856V321.009z
			 M444.437,245.703H397.58v-46.855h46.856V245.703z M444.437,170.398H397.58v-46.855h46.856V170.398z"
    />
    <path
      d="M0,438.372h77.954V358.66h33.065V81.482H0V438.372z M40.302,123.543h46.856v46.855H40.302V123.543z M40.302,198.848
			h46.856v46.855H40.302V198.848z M40.302,274.151h46.856v46.855H40.302V274.151z"
    />
  </svg>
);
