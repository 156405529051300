import { CleaningServices } from '@mui/icons-material';
import PublicIcon from '@mui/icons-material/Public';
import { InspectionIcon } from 'client/components/CustomIcons//InspectionsIcon';
import { ScenariosIcon } from 'client/components/CustomIcons//ScenariosIcon';
import { CustodiansIcon } from 'client/components/CustomIcons//CustodiansIcon';
import { ReportsIcon } from 'client/components/CustomIcons//ReportsIcon';
import { BuildingsIcon } from 'client/components/CustomIcons//BuildingsIcon';
import { RoomsIcon } from 'client/components/CustomIcons//RoomsIcon';
import { SettingsIcon } from 'client/components/CustomIcons//SettingsIcon';
import { ZonesIcon } from 'client/components/CustomIcons//ZonesIcon';
import { BaseModule } from './base';

export class ModuleCustodian extends BaseModule {
  constructor() {
    super({
      name: 'DabbleCUSTODIAN',
      shortName: 'CUSTODIAN',
      baseRoute: 'custodian',
      icon: CleaningServices,
      textColor: '#f8f8f8',
      secondaryTextColor: '#fff',
      secondaryColor: '#c26f1d',
      backgroundColor: '#995717',
      navButtons: [
        {
          type: 'site',
          text: 'Site',
          icon: PublicIcon,
          route: '/custodian/sites',
          quickPickType: 'site',
          matchRoutes: ['/custodian/sites/:site_id', '/custodian/sites'],
        },
        // {type: 'date', text: 'Date', icon: InspectionIcon, route: ''},
        {
          type: 'nav',
          text: 'Scenarios',
          icon: ScenariosIcon,
          route: '/custodian/sites/:site_id/scenarios',
          requiredParams: ['site_id'],
          matchRoutes: ['/custodian/sites/:site_id/scenarios/:scenario_id'],
        },
        {
          type: 'nav',
          text: 'Buildings',
          icon: BuildingsIcon,
          route: '/custodian/sites/:site_id/scenarios/:scenario_id/buildings',
          requiredParams: ['scenario_id', 'site_id'],
          matchRoutes: [
            '/custodian/sites/:site_id/scenarios/:scenario_id/buildings/:bldg_id',
          ],
        },
        {
          type: 'nav',
          text: 'Rooms',
          icon: RoomsIcon,
          route:
            '/custodian/sites/:site_id/scenarios/:scenario_id/buildings/:bldg_id/details/list',
          requiredParams: ['scenario_id', 'bldg_id', 'site_id'],
          matchRoutes: [
            '/custodian/sites/:site_id/scenarios/:scenario_id/buildings/:bldg_id/details/:tabName',
            '/custodian/sites/:site_id/scenarios/:scenario_id/buildings/:bldg_id/spaces/:space_id/details',
          ],
        },

        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'Custodians',
          icon: CustodiansIcon,
          route: '/custodian/sites/:site_id/scenarios/:scenario_id/custodians',
          requiredParams: ['scenario_id', 'site_id'],
          matchRoutes: [
            '/custodian/sites/:site_id/scenarios/:scenario_id/custodians/:user_id',
          ],
        },
        {
          type: 'nav',
          text: 'Zones',
          icon: ZonesIcon,
          route: '/custodian/sites/:site_id/scenarios/:scenario_id/zones',
          requiredParams: ['scenario_id', 'site_id'],
          matchRoutes: [
            '/custodian/sites/:site_id/scenarios/:scenario_id/zones/:zone_id',
          ],
        },
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'Reports',
          icon: ReportsIcon,
          route: '/custodian/sites/:site_id/scenarios/:scenario_id/reports',
          requiredParams: ['scenario_id', 'site_id'],
        },
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'Settings',
          icon: SettingsIcon,
          route: '/custodian/sites/:site_id/scenarios/:scenario_id/settings',
          requiredParams: ['scenario_id', 'site_id'],
          matchRoutes: [
            '/custodian/sites/:site_id/scenarios/:scenario_id/settings/basics',
            '/custodian/sites/:site_id/scenarios/:scenario_id/settings/teams',
            '/custodian/sites/:site_id/scenarios/:scenario_id/settings/levels',
            '/custodian/sites/:site_id/scenarios/:scenario_id/settings/tasks',
            '/custodian/sites/:site_id/scenarios/:scenario_id/settings/profiles',
            '/custodian/sites/:site_id/scenarios/:scenario_id/settings/roomUses',
            '/custodian/sites/:site_id/scenarios/:scenario_id/settings/access',
            '/custodian/sites/:site_id/scenarios/:scenario_id/settings/actions',
          ],
        },
      ],
    });
  }

  protected setup() {
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }
}
