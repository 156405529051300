import {
  GridActionsCellItem, GridRowId, GridRowModes, GridRowModesModel,
} from '@mui/x-data-grid-pro';
import { NavLink } from 'react-router-dom';
import {
  Cancel, Delete, Edit, Link as LinkIcon, Save,
} from '@mui/icons-material';
import React from 'react';
import { IDfGridColDef } from 'client/components/generic/DfDataGrid';

export function getNavigateColumn<T>(
  t: any,
  data: (T & { _id: string })[],
  getUrl: ({ row }: { row: (T & { _id: string }) }) => string,
): IDfGridColDef {
  return {
    headerName: t('Navigate'),
    resizable: false,
    sortable: false,
    field: 'navigate',
    type: 'actions',
    width: 40,
    getActions: ({ id }) => {
      const row = data?.find((x) => x._id === id);
      const url = row && getUrl ? getUrl({ row }) : '';
      return [
        <GridActionsCellItem
          component={NavLink}
          // @ts-ignore //TODO: Make this work with types
          to={url}
          icon={<LinkIcon />}
          label={t('Navigate')}
          sx={{
            color: 'primary.main',
          }}
        />,
      ];
    },
  };
}

export function getSimpleEditColumn<T>(
  t: any,
  data: (T & { _id: string })[],
  onRowClickEdit: ({ row }: { row?: (T & { _id: string }) }) => void,
): IDfGridColDef {
  return {
    headerName: t('Actions'),
    resizable: false,
    sortable: false,
    field: 'simpleEdit',
    type: 'actions',
    width: 40,
    getActions: ({ id }) => {
      const row = data?.find((x) => x._id === id);
      return [
        <GridActionsCellItem
          onClick={() => onRowClickEdit({ row })}
          icon={<Edit />}
          label={t('Edit')}
          sx={{
            color: 'primary.main',
          }}
        />,
      ];
    },
  };
}

export function getPrefixColumn<T>(
  {
    t,
    data,
    onRowClickEdit,
    onRowClickDelete,
    onRowClickNavigate,
  }: {
    t: any,
    data: (T & { _id: string })[],
    onRowClickEdit?: ({ row }: { row?: (T & { _id: string }) }) => void,
    onRowClickDelete?: ({ row }: { row?: (T & { _id: string }) }) => void,
    onRowClickNavigate?: ({ row }: { row?: (T & { _id: string }) }) => string | undefined,
  },
): IDfGridColDef {
  const width = (onRowClickEdit ? 100 : 0) + (onRowClickDelete ? 100 : 0) + (onRowClickNavigate ? 100 : 0);

  return {
    headerName: t('Actions'),
    resizable: false,
    sortable: false,
    field: 'prefixActions',
    type: 'actions',
    width,
    getActions: ({ id }) => {
      const row = data?.find((x) => x._id === id);
      const actions = [];

      if (onRowClickNavigate) {
        const url = row ? onRowClickNavigate({ row }) : '';
        actions.push(
          <GridActionsCellItem
            title={t('Navigate')}
            component={NavLink}
            // @ts-ignore //TODO: Make this work with types
            to={url}
            icon={<LinkIcon />}
            label={t('Navigate')}
            sx={{
              color: 'primary.main',
            }}
          />,
        );
      }
      if (onRowClickEdit) {
        actions.push(<GridActionsCellItem
          title={t('Edit')}
          onClick={() => onRowClickEdit({ row })}
          icon={<Edit />}
          label={t('Edit')}
          sx={{
            color: 'primary.main',
          }}
        />);
      }
      if (onRowClickDelete) {
        actions.push(<GridActionsCellItem
          title={t('Delete')}
          onClick={() => onRowClickDelete({ row })}
          icon={<Delete />}
          label={t('Delete')}
          sx={{
            color: 'primary.main',
          }}
        />);
      }

      return actions;
    },
  };
}

export function getEditColumn({
  t,
  rowModesModel,
  setRowModesModel,
  data,
  processRowUpdate,
  processRowRemove,
}: {
  t: any;
  rowModesModel: GridRowModesModel;
  setRowModesModel: (x: GridRowModesModel) => void;
  data?: any[];
  processRowUpdate?: (updatedRow: any, originalRow?: any) => void;
  processRowRemove?: (updatedRow: any, originalRow?: any) => void;
}): IDfGridColDef {
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    console.log('Handling Delete', processRowRemove, data?.find((row) => row._id === id));
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    processRowRemove?.(data?.find((row) => row._id === id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  return {
    headerName: t('Actions'),
    field: 'actions',
    type: 'actions',
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
      const actionsToReturn: JSX.Element[] = [];
      if (isInEditMode) {
        actionsToReturn.push(<GridActionsCellItem
          icon={<Save />}
          label="Save"
          sx={{
            color: 'primary.main',
          }}
          onClick={handleSaveClick(id)}
        />);

        actionsToReturn.push(<GridActionsCellItem
          icon={<Cancel />}
          label="Cancel"
          className="textPrimary"
          onClick={handleCancelClick(id)}
          color="inherit"
        />);
      } else {
        actionsToReturn.push(<GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />);
      }

      if (processRowRemove) {
        actionsToReturn.push(<GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />);
      }

      return actionsToReturn;
    },
  };
}

export function getDeleteColumn({
  t,
  rowModesModel,
  setRowModesModel,
  data,
  processRowRemove,
}: {
  t: any;
  rowModesModel: GridRowModesModel;
  setRowModesModel: (x: GridRowModesModel) => void;
  data?: any[];
  processRowRemove?: (updatedRow: any, originalRow?: any) => void;
}): IDfGridColDef {
  const handleDeleteClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    processRowRemove?.(data?.find((row) => row._id === id));
  };

  return {
    headerName: t('Actions'),
    field: 'delete',
    type: 'actions',
    getActions: ({ id }) => [
      <GridActionsCellItem
        icon={<Delete />}
        label="Delete"
        onClick={handleDeleteClick(id)}
        color="inherit"
      />,
    ],
  };
}
