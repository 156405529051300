import { makeAutoObservable } from 'mobx';
import { ParentAppState } from '../parentAppState';

export class UiState {
  public leftNavExpanded: boolean = true;

  public leftNavHelper?: 'sites' | 'date' | 'buildings' | 'rooms';

  public rightHelperExpanded: boolean = true;

  private readonly _appState: ParentAppState;

  constructor(appState: ParentAppState) {
    makeAutoObservable(this, {}, { autoBind: true });
    this._appState = appState;
  }

  public toggleLeftNav() {
    this.setLeftNavExpanded(!this.leftNavExpanded);
  }

  public toggleRightHelper() {
    this.setRightHelper(!this.rightHelperExpanded);
  }

  public toggleLeftNavHelper(helper: 'sites' | 'date' | 'buildings' | 'rooms') {
    this.setLeftNavHelper(helper === this.leftNavHelper ? undefined : helper);
  }

  public setLeftNavHelper(helper?: 'sites' | 'date' | 'buildings' | 'rooms') {
    this.leftNavHelper = helper;
    this.saveUiState();
  }

  public setRightHelper(state: boolean) {
    console.log('Setting Right', state);
    this.rightHelperExpanded = state;
    this.saveUiState();
  }

  public setLeftNavExpanded(state: boolean) {
    this.leftNavExpanded = state;
    this.saveUiState();
    console.log('SET EXPANDED', state);
  }

  get leftHelperExpanded() {
    return !!this.leftNavHelper;
  }

  private saveUiState() {
    window.localStorage.setItem(
      `UiState_${this._appState.user?.id}`,
      JSON.stringify({
        leftNavExpanded: this.leftNavExpanded,
        leftNavHelper: this.leftNavHelper,
        rightHelperExpanded: this.rightHelperExpanded,
      }),
    );
  }

  public loadUiState() {
    const state = window.localStorage.getItem(
      `UiState_${this._appState.user?.id}`,
    );
    if (state) {
      const parsed = JSON.parse(state);

      if (parsed.leftNavExpanded !== undefined) this.setLeftNavExpanded(parsed.leftNavExpanded);
      if (parsed.leftNavHelper !== undefined) this.setLeftNavHelper(parsed.leftNavHelper);
      if (parsed.rightHelperExpanded !== undefined) this.setRightHelper(parsed.rightHelperExpanded);
    }
  }
}
