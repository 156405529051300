import axios, { AxiosRequestConfig } from 'axios';
import { ApiCallParams } from './ApiCallLoader';

export class ApiCallsBase {
  // @ts-ignore
  private baseUrl: string = import.meta.env.VITE_API ?? '';

  GetToken() {
    return window.localStorage.getItem('token');
  }

  JoinUrlWithParams(url: string, params: ApiCallParams) {
    return Object.entries(params).reduce(
      (acc, [key, value]) => url.replace(`:${key}`, value?.toString() ?? ''),
      url,
    );
  }

  SimpleGet<T>(
    url: string,
    params: ApiCallParams,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    console.log('SHOULD GET WITH BASE URL', this.baseUrl);

    return new Promise((resolve, reject) => {
      axios
        .get(`${this.baseUrl}/${this.JoinUrlWithParams(url, params)}`, {
          headers: { Authorization: `Basic ${this.GetToken()}` },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  SimplePost<T>(url: string, params: ApiCallParams, body?: any): Promise<T> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.baseUrl}/${this.JoinUrlWithParams(url, params)}`, body, {
          headers: { Authorization: `Basic ${this.GetToken()}` },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  SimplePatch<T>(url: string, params: ApiCallParams, body?: any): Promise<T> {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${this.baseUrl}/${this.JoinUrlWithParams(url, params)}`, body, {
          headers: { Authorization: `Basic ${this.GetToken()}` },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  SimplePut<T>(url: string, params: ApiCallParams, body?: any): Promise<T> {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.baseUrl}/${this.JoinUrlWithParams(url, params)}`, body, {
          headers: { Authorization: `Basic ${this.GetToken()}` },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  SimpleDelete<T>(url: string, params: ApiCallParams): Promise<T> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.baseUrl}/${this.JoinUrlWithParams(url, params)}`, {
          headers: { Authorization: `Basic ${this.GetToken()}` },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  SimpleDownload(url: string, filename: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.baseUrl}/${url}`, {
          headers: { Authorization: `Basic ${this.GetToken()}` },
          responseType: 'blob',
        })
        .then((result) => {
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename); // or any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
