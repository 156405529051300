import { makeAutoObservable } from 'mobx';
import { CustodialScenario } from '../classes/custodian/custodialScenario';
import { Site } from '../classes/location/site';
import ApiCalls from '../apiCalls';
import { nameSort } from '../functions/nameSort';

export class CustodianState {
  public currentScenario?: CustodialScenario;

  public scenarios: CustodialScenario[] = [];

  private readonly _site: Site;

  constructor(site: Site) {
    makeAutoObservable(this, {}, { autoBind: true });
    this._site = site;
  }

  public fetch() {
    return ApiCalls.getCustodialScenarios({ site_id: this._site.id }).then(
      (scenarios) => {
        this.setScenarios(
          scenarios.map(
            (scenario) => new CustodialScenario(scenario, this._site),
          ),
        );

        const currentScenario = this.scenarios.find(
          (scenario) => scenario.isDefault,
        );

        this.setCurrentScenario(currentScenario);
        if (currentScenario) {
          console.log('FETCH SCENARIO');
          return currentScenario.fetch();
        }
      },
    );
  }

  setScenarios(scenarios: CustodialScenario[]) {
    this.scenarios = nameSort(scenarios);

    if (!this.currentScenario) {
      this.setCurrentScenario(
        this.scenarios.find((scenario) => scenario.isDefault),
      );
    }
  }

  setCurrentScenario(scenario?: CustodialScenario) {
    this.currentScenario = scenario;
  }
}
