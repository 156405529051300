import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import { LicenseInfo } from '@mui/x-license';
import axios from 'axios';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfirmProvider } from 'material-ui-confirm';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Loading } from 'client/components/generic';
import { ToastContainer } from 'react-toastify';
import { AppRouter } from './appRouter';

import 'react-toastify/dist/ReactToastify.css';
// @ts-ignore
const { VITE_MUI_LICENSE_KEY, VITE_API, DEV } = import.meta.env;

LicenseInfo.setLicenseKey(VITE_MUI_LICENSE_KEY);
axios.defaults.baseURL = VITE_API;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { networkMode: DEV ? 'always' : undefined },
    mutations: { networkMode: DEV ? 'always' : undefined },
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <React.StrictMode>

  <Suspense fallback={<Loading />}>
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ConfirmProvider>
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </ConfirmProvider>
        <ToastContainer />
      </LocalizationProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  </Suspense>,
  // </React.StrictMode>
);
