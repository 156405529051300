import React from 'react';
import { DabblefoxLogo } from 'client/components/CustomIcons/DabblefoxLogo';
import '../login/login.css';

export const Loading = () => (
  <div id="loginBG">
    <div className="centerLogin">
      <div className="loginLogo">
        <DabblefoxLogo />
      </div>
      <h3 style={{ padding: '20px' }}>Loading...</h3>
    </div>
  </div>
);
