import React from 'react';

// @ts-ignore
export const ScenariosIcon = () => (
  <svg
    id="Capa_1"
    enableBackground="new 0 0 512 512"
    height="100%"
    viewBox="0 0 512 512"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="scenariosIconTitle">Scenarios Icon</title>
    <desc id="scenariosIconDesc">Scenarios Icon</desc>

    <path
      d="m434.996 72.71c-7.934-21.443-28.582-36.77-52.745-36.77h-56.784l-20.03-20.03c-10.1-10.111-24.095-15.91-38.397-15.91h-180.39c-31.005 0-56.23 25.225-56.23 56.23v26.69c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-26.69c0-22.734 18.496-41.23 41.23-41.23h180.39c1.464 0 2.923.093 4.371.258v45.268c0 34.977 28.455 63.432 63.431 63.432h45.281c.159 1.442.248 2.9.248 4.373v255.569c0 22.734-18.496 41.23-41.23 41.23h-252.491c-22.734 0-41.23-18.496-41.23-41.23v-270.98c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v270.98c0 27.709 20.152 50.785 46.566 55.382 6.939 18.865 23.499 32.528 43.11 35.949 8.056 21.883 29.066 36.77 52.763 36.77h252.493c31.004 0 56.227-25.223 56.227-56.227v-327.674c.001-27.364-20.085-50.767-46.583-55.39zm-100.154 36.247c-26.705 0-48.431-21.727-48.431-48.432v-40.386c3.065 1.732 5.909 3.864 8.416 6.374l74.033 74.034c2.541 2.538 4.681 5.37 6.41 8.41zm131.738 346.816c0 22.732-18.495 41.227-41.227 41.227h-252.493c-15.104 0-28.72-8.242-35.902-20.93h245.292c31 0 56.22-25.225 56.22-56.23v-37.4c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v37.4c0 22.734-18.491 41.23-41.22 41.23h-252.5c-15.104 0-28.719-8.247-35.901-20.94h245.291c31.005 0 56.23-25.225 56.23-56.23v-255.57c0-14.513-5.65-28.148-15.907-38.393l-38.996-38.997h41.784c22.729 0 41.22 18.496 41.22 41.23v260.27c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-260.27c0-1.067-.036-2.125-.095-3.178 16.407 5.512 28.205 21.147 28.205 39.108v327.673z"
    />
    <path
      d="m84.282 161.744c10.284 0 15.062 3.723 21.111 8.436 6.635 5.17 14.893 11.604 30.33 11.604 15.438 0 23.696-6.434 30.331-11.604 6.05-4.713 10.828-8.436 21.112-8.436s15.062 3.723 21.112 8.436c6.635 5.17 14.893 11.604 30.33 11.604 15.439 0 23.698-6.434 30.333-11.603 6.051-4.713 10.83-8.437 21.116-8.437s15.065 3.723 21.116 8.437c6.636 5.169 14.895 11.603 30.333 11.603 4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5c-10.286 0-15.064-3.723-21.115-8.437-6.636-5.169-14.895-11.603-30.333-11.603s-23.698 6.434-30.333 11.603c-6.051 4.713-10.83 8.437-21.116 8.437-10.284 0-15.062-3.723-21.111-8.436-6.635-5.169-14.893-11.604-30.331-11.604s-23.696 6.434-30.331 11.604c-6.05 4.713-10.828 8.436-21.112 8.436s-15.062-3.723-21.111-8.436c-6.635-5.17-14.893-11.604-30.33-11.604-4.142 0-7.5 3.358-7.5 7.5s3.357 7.5 7.499 7.5z"
    />
    <path
      d="m84.282 210.033c10.284 0 15.062 3.723 21.111 8.436 6.635 5.17 14.893 11.604 30.33 11.604 15.438 0 23.696-6.434 30.331-11.604 6.05-4.713 10.828-8.437 21.112-8.437s15.062 3.723 21.112 8.437c6.635 5.169 14.893 11.604 30.33 11.604 15.439 0 23.698-6.434 30.334-11.604 6.05-4.713 10.83-8.437 21.115-8.437s15.065 3.723 21.115 8.437c6.636 5.17 14.895 11.604 30.333 11.604 4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5c-10.286 0-15.064-3.723-21.115-8.437-6.636-5.169-14.895-11.604-30.334-11.604s-23.698 6.434-30.334 11.604c-6.05 4.713-10.83 8.437-21.115 8.437-10.284 0-15.062-3.723-21.111-8.436-6.635-5.17-14.893-11.604-30.331-11.604s-23.696 6.434-30.331 11.604c-6.05 4.713-10.828 8.437-21.112 8.437s-15.062-3.723-21.111-8.436c-6.635-5.17-14.893-11.604-30.33-11.604-4.142 0-7.5 3.358-7.5 7.5s3.359 7.499 7.501 7.499z"
    />
    <path
      d="m84.282 258.323c10.284 0 15.062 3.723 21.111 8.436 6.635 5.17 14.893 11.604 30.33 11.604 15.438 0 23.696-6.434 30.331-11.604 6.05-4.713 10.828-8.436 21.112-8.436s15.062 3.723 21.112 8.436c6.635 5.17 14.893 11.604 30.33 11.604 15.439 0 23.698-6.434 30.333-11.603 6.051-4.713 10.83-8.437 21.116-8.437s15.065 3.723 21.116 8.437c6.636 5.169 14.895 11.603 30.333 11.603 4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5c-10.286 0-15.064-3.723-21.115-8.437-6.636-5.169-14.895-11.603-30.333-11.603s-23.698 6.434-30.333 11.603c-6.051 4.713-10.83 8.437-21.116 8.437-10.284 0-15.062-3.723-21.111-8.436-6.635-5.17-14.893-11.604-30.331-11.604s-23.696 6.434-30.331 11.604c-6.05 4.713-10.828 8.436-21.112 8.436s-15.062-3.723-21.111-8.436c-6.635-5.17-14.893-11.604-30.33-11.604-4.142 0-7.5 3.358-7.5 7.5s3.357 7.5 7.499 7.5z"
    />
    <path
      d="m84.282 306.613c10.284 0 15.062 3.723 21.111 8.436 6.635 5.17 14.893 11.604 30.33 11.604 15.438 0 23.696-6.434 30.331-11.604 6.05-4.713 10.828-8.437 21.112-8.437s15.062 3.723 21.112 8.437c6.635 5.169 14.893 11.604 30.33 11.604 15.439 0 23.698-6.434 30.334-11.604 6.05-4.713 10.83-8.437 21.115-8.437s15.065 3.723 21.115 8.437c6.636 5.17 14.895 11.604 30.333 11.604 4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5c-10.286 0-15.064-3.723-21.115-8.437-6.636-5.169-14.895-11.604-30.334-11.604s-23.698 6.434-30.334 11.604c-6.05 4.713-10.83 8.437-21.115 8.437-10.284 0-15.062-3.723-21.111-8.436-6.635-5.17-14.893-11.604-30.331-11.604s-23.696 6.434-30.331 11.604c-6.05 4.713-10.828 8.437-21.112 8.437s-15.062-3.723-21.111-8.436c-6.635-5.17-14.893-11.604-30.33-11.604-4.142 0-7.5 3.358-7.5 7.5s3.359 7.499 7.501 7.499z"
    />
    <path
      d="m84.282 354.903c10.284 0 15.062 3.723 21.111 8.436 6.635 5.17 14.893 11.604 30.33 11.604 15.438 0 23.696-6.434 30.331-11.604 6.05-4.713 10.828-8.436 21.112-8.436s15.062 3.723 21.112 8.436c6.635 5.17 14.893 11.604 30.33 11.604 15.439 0 23.698-6.434 30.333-11.603 6.051-4.713 10.83-8.437 21.116-8.437s15.065 3.723 21.116 8.437c6.636 5.169 14.895 11.603 30.333 11.603 4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5c-10.286 0-15.064-3.723-21.115-8.437-6.636-5.169-14.895-11.603-30.333-11.603s-23.698 6.434-30.333 11.603c-6.051 4.713-10.83 8.437-21.116 8.437-10.284 0-15.062-3.723-21.111-8.436-6.635-5.169-14.893-11.604-30.331-11.604s-23.696 6.434-30.331 11.604c-6.05 4.713-10.828 8.436-21.112 8.436s-15.062-3.723-21.111-8.436c-6.635-5.17-14.893-11.604-30.33-11.604-4.142 0-7.5 3.358-7.5 7.5s3.357 7.5 7.499 7.5z"
    />
  </svg>
);
