import React, { ChangeEventHandler } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { NumericFormat } from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<typeof NumericFormat, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isAllowed={({ floatValue }) => floatValue ? (floatValue <= 100 && floatValue >= 0) : true}
        suffix="%"
      />
    );
  },
);

export const DfPercentageField = (params: {
    error?: boolean;
    name?: string;
    size?: 'small' | 'medium';
    disabled?: boolean;
    fullWidth?: boolean;
    label?: string;
    value: number;
    defaultValue?: string | number;
    placeholder?: string;
    multiline?: boolean;
    rows?: number;
    rowsMax?: number;
    sx?: TextFieldProps['sx'];
    InputProps?: TextFieldProps['InputProps'];
    onBlur?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    autoFocus?: boolean;
    variant?: 'standard' | 'outlined' | 'filled';
  }) => (
    <TextField
      {...params}
      style={{ marginTop: '.5em' }}
      sx={{ backgroundColor: 'white', ...params.sx }}
      InputLabelProps={{ shrink: true }}
      InputProps={{ ...params.InputProps, inputComponent: NumberFormatCustom as any }}
      fullWidth
      variant={params.variant ?? 'outlined'}
      onBlur={params.onBlur}
    />
);
