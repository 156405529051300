import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserMutation } from 'client/utilities/hooks';
import { LoginInput } from './loginInput';

interface ILoginComponent {
  email: string;
  setEmail: (v: string) => void;
  setPage: (v: string) => void;
}

export const LoginForgotPassword = ({
  email,
  setEmail,
  setPage,
}: ILoginComponent) => {
  const { t } = useTranslation();
  const { mutation } = useUserMutation();
  const [message, setMessage] = useState<string>('');

  function handleSubmit(e: any) {
    e.preventDefault();
    mutation
      .mutateAsync({ method: 'resetPassword', email })
      .then(() => setMessage(t('Password reset email sent')));
  }

  if (message) {
    return (
      <div>
        <form onSubmit={handleSubmit}>
          {message}
          <div style={{ textAlign: 'center' }}>
            <a href="#" onClick={() => setPage('login')}>
              <button className="btn btn-default" type="submit">
                {t('Sign In')}
              </button>
            </a>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <LoginInput
          type="email"
          handleChange={setEmail}
          name="email"
          label="Email Address"
          value={email}
        />
        <div style={{ textAlign: 'center' }}>
          <button className="btn btn-default" type="submit">
            Reset Password
          </button>
        </div>
        <small>
          <a href="#" onClick={() => setPage('login')}>
            {t('Sign In?')}
          </a>
        </small>
      </form>
    </div>
  );
};
