import SearchIcon from '@mui/icons-material/Search';
import PublicIcon from '@mui/icons-material/Public';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { InspectionIcon } from 'client/components/CustomIcons//InspectionsIcon';
import { RoomsIcon } from 'client/components/CustomIcons//RoomsIcon';
import { InspectorIcon } from 'client/components/CustomIcons//InspectorIcon';
import { BuildingsIcon } from 'client/components/CustomIcons//BuildingsIcon';
import { SettingsIcon } from 'client/components/CustomIcons//SettingsIcon';
import { ReportsIcon } from 'client/components/CustomIcons//ReportsIcon';
import { CustodiansIcon } from 'client/components/CustomIcons//CustodiansIcon';
import { DashboardIcon } from 'client/components/CustomIcons//DashboardIcon';
import { BaseModule } from './base';

export class ModuleQC extends BaseModule {
  constructor() {
    super({
      name: 'DabbleQC',
      shortName: 'QC',
      baseRoute: 'qc',
      icon: SearchIcon,
      textColor: '#f8f8f8',
      secondaryTextColor: '#fff',
      secondaryColor: '#699',
      backgroundColor: '#0d4d4d',
      navButtons: [
        {
          type: 'site',
          text: 'Sites',
          icon: PublicIcon,
          quickPickType: 'site',
          route: '',
        },
        {
          type: 'nav',
          text: 'Buildings',
          icon: BuildingsIcon,
          route: '/qc/sites/:site_id/buildings',
          requiredParams: ['site_id'],
        },
        {
          type: 'nav',
          text: 'Rooms',
          icon: RoomsIcon,
          route: '/qc/sites/:site_id/buildings/:bldg_id/spaces',
          requiredParams: ['bldg_id'],
        },
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'Custodians',
          icon: CustodiansIcon,
          route: '/qc/sites/:site_id/custodians',
          requiredParams: ['site_id'],
        },
        {
          type: 'nav',
          text: 'Inspectors',
          icon: InspectorIcon,
          route: '/qc/sites/:site_id/inspectors',
          requiredParams: ['site_id'],
        },
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'Inspections',
          icon: InspectionIcon,
          route: '/qc/sites/:site_id/inspections',
          requiredParams: ['site_id'],
        },
        {
          type: 'nav',
          text: 'Schedules',
          icon: EventRepeatIcon,
          route: '/qc/sites/:site_id/generators',
          requiredParams: ['site_id'],
        },
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'Dashboard',
          icon: DashboardIcon,
          route: '/qc/sites/:site_id/dashboards',
          requiredParams: ['site_id'],
        },
        {
          type: 'nav',
          text: 'Reports',
          icon: ReportsIcon,
          route: '/qc/sites/:site_id/reports',
          requiredParams: ['site_id'],
        },
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'Settings',
          icon: SettingsIcon,
          route: '/qc/sites/:site_id/settings',
          requiredParams: ['site_id'],
        },
      ],
    });
  }

  protected setup() {
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }
}
