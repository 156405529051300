import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { InspectionIcon } from 'client/components/CustomIcons//InspectionsIcon';
import { DashboardIcon } from 'client/components/CustomIcons//DashboardIcon';
import { UsersIcon } from 'client/components/CustomIcons//UsersIcon';
import { MirrorIcon } from 'client/components/CustomIcons//MirrorIcon';
import { BaseModule } from './base';

export class ModuleAdmin extends BaseModule {
  constructor() {
    super({
      name: 'DabbleADMIN',
      shortName: 'ADMIN',
      baseRoute: 'admin',
      icon: AdminPanelSettingsIcon,
      textColor: '#222',
      secondaryTextColor: '#fff',
      secondaryColor: '#ff8e1b',
      backgroundColor: '#ffc81e',
      navButtons: [
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        // {type: 'nav', text: 'Dashboard', icon: DashboardIcon, route: '/admin/main/main'},
        {
          type: 'nav',
          text: 'Users',
          icon: UsersIcon,
          route: '/admin/users',
        },
        {
          type: 'nav',
          text: 'Mirrors',
          icon: MirrorIcon,
          route: '/admin/mirrors',
        },
        {
          type: 'nav',
          text: 'Logs',
          icon: DashboardIcon,
          route: '/admin/logs',
        },
      ],
      allowLeftNavHelper: false,
    });
  }

  protected setup() {
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }
}
