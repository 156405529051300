import React from 'react';

export const DabblefoxLogo = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 250 40"
    xmlSpace="preserve"
    aria-labelledby="dabblefoxLogoTitle dabblefoxLogoDesc"
    role="img"
  >
    <title id="dabblefoxLogoTitle">Dabblefox Logo</title>
    <desc id="dabblefoxLogoDesc">Dabblefox Logo</desc>
    <g>
      <g>
        <path
          fill="#560000"
          d="M78.637,35.146c-1.158-0.029-2.058-0.044-2.7-0.044c-0.698,0-1.664,0.015-2.896,0.044l0.523-3.808 c-0.688,0.831-1.272,1.474-1.753,1.926s-1.063,0.872-1.747,1.258c-0.684,0.387-1.41,0.675-2.181,0.864
			c-0.771,0.189-1.562,0.285-2.371,0.285c-1.341,0-2.575-0.256-3.702-0.766c-1.129-0.511-2.063-1.229-2.805-2.155	c-0.741-0.928-1.251-2.028-1.527-3.305c-0.277-1.277-0.354-2.607-0.23-3.994c0.171-1.911,0.686-3.774,1.544-5.591
			c0.86-1.816,1.947-3.286,3.262-4.409c1.313-1.124,2.76-1.918,4.336-2.385c1.577-0.467,3.244-0.7,5.003-0.7 c1.27,0,2.696,0.125,4.279,0.372c0.271-1.896,0.502-3.887,0.688-5.974l0.133-1.488c1.312,0.029,2.316,0.044,3.016,0.044
			c0.909,0,1.932-0.015,3.065-0.044c-0.637,3.706-1.375,8.75-2.217,15.132C79.517,26.79,78.943,31.703,78.637,35.146z
			 M75.086,16.896c-1.021-0.292-2.015-0.438-2.978-0.438c-1.104,0-2.173,0.161-3.207,0.482c-1.034,0.321-1.974,0.868-2.818,1.641
			c-0.843,0.773-1.529,1.766-2.056,2.977c-0.527,1.211-0.841,2.377-0.941,3.501c-0.1,1.123,0.001,2.151,0.302,3.085
			c0.3,0.935,0.832,1.672,1.594,2.21c0.762,0.54,1.652,0.811,2.672,0.811c0.769,0,1.479-0.143,2.133-0.427
			c0.654-0.285,1.266-0.722,1.835-1.313c0.569-0.59,1.073-1.353,1.514-2.287c0.439-0.933,0.804-2.173,1.096-3.719
			C74.431,22.278,74.716,20.104,75.086,16.896z"
        />
        <path
          fill="#560000"
          d="M85.254,17.771c0.289-1.051,0.478-1.787,0.564-2.21c0.086-0.423,0.23-1.065,0.434-1.926
			c1.94-0.467,3.646-0.794,5.115-0.985c1.47-0.188,2.882-0.284,4.236-0.284c1.536,0,2.931,0.186,4.183,0.558
			c1.253,0.372,2.204,0.966,2.858,1.783c0.653,0.817,0.912,1.991,0.775,3.523c-0.074,0.832-0.374,2.951-0.899,6.357
			c-0.525,3.406-0.972,6.927-1.336,10.559c-1.061-0.029-2.037-0.044-2.932-0.044c-0.854,0-1.854,0.015-3.003,0.044
			c0.341-1.765,0.671-3.808,0.991-6.127l0.761-5.449c-0.563,0.364-0.992,0.62-1.284,0.767c-0.292,0.146-0.619,0.281-0.98,0.404
			c-0.36,0.124-0.992,0.314-1.896,0.569s-1.629,0.478-2.178,0.667c-0.548,0.189-1.046,0.404-1.494,0.646
			c-0.447,0.241-0.831,0.511-1.15,0.81c-0.321,0.3-0.562,0.613-0.724,0.94c-0.163,0.329-0.261,0.684-0.294,1.062
			c-0.055,0.613,0.069,1.142,0.371,1.587c0.303,0.445,0.704,0.756,1.206,0.93c0.501,0.175,1.101,0.263,1.799,0.263
			c0.447,0,0.923-0.036,1.424-0.109c0.503-0.072,1.186-0.255,2.05-0.547c-0.367,0.831-0.82,2.071-1.357,3.72
			c-1.008,0.176-1.807,0.284-2.396,0.328c-0.591,0.044-1.138,0.066-1.641,0.066c-1.76,0-3.184-0.248-4.271-0.744
			s-1.852-1.284-2.293-2.364c-0.441-1.078-0.613-2.166-0.516-3.26c0.056-0.627,0.216-1.247,0.479-1.86
			c0.264-0.612,0.622-1.174,1.072-1.685c0.451-0.511,1.02-1.003,1.704-1.478c0.685-0.474,1.655-0.933,2.911-1.378
			c1.255-0.445,2.902-0.894,4.942-1.346c0.54-0.117,1.105-0.259,1.693-0.427c0.588-0.167,1.061-0.332,1.417-0.492
			c0.356-0.161,0.672-0.369,0.946-0.624c0.274-0.256,0.483-0.521,0.627-0.8c0.144-0.276,0.229-0.561,0.254-0.853
			c0.051-0.569-0.084-1.05-0.405-1.444s-0.758-0.667-1.311-0.82c-0.553-0.153-1.172-0.23-1.856-0.23
			C91.309,15.866,88.442,16.501,85.254,17.771z"
        />
        <path
          fill="#560000"
          d="M104.765,35.146c0.499-2.771,1.207-7.589,2.12-14.454c0.915-6.863,1.531-12.002,1.85-15.417
			c1.239,0.029,2.311,0.044,3.218,0.044c0.905,0,1.877-0.015,2.911-0.044c-0.419,2.058-0.838,4.654-1.257,7.791
			c1.394-0.277,2.5-0.463,3.317-0.558c0.819-0.095,1.625-0.142,2.422-0.142c2.192,0,4.007,0.361,5.443,1.083
			c1.438,0.722,2.535,1.918,3.294,3.588c0.759,1.671,1.033,3.694,0.821,6.073c-0.148,1.663-0.52,3.278-1.112,4.847
			c-0.595,1.568-1.396,2.962-2.404,4.18s-2.18,2.112-3.515,2.681c-1.334,0.568-2.755,0.854-4.262,0.854
			c-0.726,0-1.394-0.069-2.002-0.208c-0.609-0.14-1.163-0.339-1.664-0.602c-0.5-0.263-0.939-0.569-1.32-0.919
			c-0.381-0.351-0.691-0.7-0.932-1.051c-0.241-0.351-0.515-0.839-0.822-1.466l-0.423,3.72c-1.145-0.029-2.045-0.044-2.702-0.044
			C107.243,35.102,106.248,35.116,104.765,35.146z M112.821,17.53l-0.501,3.742c-0.115,0.803-0.205,1.584-0.272,2.342
			c-0.161,1.809-0.11,3.235,0.151,4.277c0.262,1.044,0.792,1.85,1.591,2.419c0.799,0.568,1.728,0.854,2.785,0.854
			c1.156,0,2.188-0.325,3.095-0.974c0.907-0.649,1.644-1.601,2.207-2.855c0.564-1.255,0.908-2.575,1.032-3.961
			c0.178-1.999-0.187-3.603-1.096-4.814c-0.908-1.211-2.351-1.817-4.327-1.817c-0.641,0-1.314,0.056-2.021,0.165
			C114.761,17.016,113.879,17.223,112.821,17.53z"
        />
        <path
          fill="#560000"
          d="M129.74,35.146c0.5-2.771,1.207-7.589,2.121-14.454c0.914-6.863,1.53-12.002,1.849-15.417
			c1.239,0.029,2.311,0.044,3.218,0.044c0.905,0,1.877-0.015,2.911-0.044c-0.419,2.058-0.838,4.654-1.257,7.791
			c1.394-0.277,2.5-0.463,3.317-0.558c0.819-0.095,1.625-0.142,2.422-0.142c2.192,0,4.007,0.361,5.443,1.083
			c1.438,0.722,2.535,1.918,3.294,3.588c0.76,1.671,1.033,3.694,0.821,6.073c-0.148,1.663-0.52,3.278-1.112,4.847
			c-0.594,1.568-1.396,2.962-2.404,4.18s-2.18,2.112-3.514,2.681c-1.335,0.568-2.756,0.854-4.263,0.854
			c-0.726,0-1.394-0.069-2.002-0.208c-0.609-0.14-1.163-0.339-1.663-0.602s-0.94-0.569-1.321-0.919
			c-0.381-0.351-0.691-0.7-0.932-1.051c-0.241-0.351-0.515-0.839-0.822-1.466l-0.423,3.72c-1.145-0.029-2.045-0.044-2.702-0.044
			C132.219,35.102,131.224,35.116,129.74,35.146z M137.797,17.53l-0.501,3.742c-0.115,0.803-0.205,1.584-0.272,2.342
			c-0.161,1.809-0.11,3.235,0.151,4.277c0.262,1.044,0.792,1.85,1.591,2.419c0.799,0.568,1.728,0.854,2.785,0.854
			c1.156,0,2.188-0.325,3.095-0.974c0.907-0.649,1.644-1.601,2.207-2.855c0.564-1.255,0.908-2.575,1.032-3.961
			c0.178-1.999-0.187-3.603-1.096-4.814c-0.908-1.211-2.351-1.817-4.327-1.817c-0.641,0-1.314,0.056-2.021,0.165
			C139.736,17.016,138.854,17.223,137.797,17.53z"
        />
        <path
          fill="#560000"
          d="M154.716,35.146c0.428-2.465,1.076-6.896,1.945-13.294c0.868-6.396,1.546-11.922,2.029-16.576
			c1.452,0.029,2.484,0.044,3.101,0.044c0.824,0,1.833-0.015,3.023-0.044l-1.213,7.987l-2.316,17.813l-0.445,4.07
			c-1.145-0.029-2.114-0.044-2.911-0.044C157.662,35.102,156.591,35.116,154.716,35.146z"
        />
        <path
          fill="#560000"
          d="M183.821,30.178c-0.26,0.861-0.655,2.407-1.188,4.64c-1.539,0.335-2.831,0.562-3.874,0.678
			c-1.044,0.117-2.09,0.176-3.138,0.176c-1.829,0-3.45-0.189-4.861-0.568c-1.413-0.379-2.618-1.075-3.617-2.088
			c-1-1.013-1.692-2.256-2.078-3.728c-0.385-1.472-0.506-3.017-0.361-4.636c0.16-1.791,0.614-3.529,1.365-5.213
			c0.75-1.683,1.715-3.06,2.893-4.132c1.179-1.071,2.479-1.83,3.901-2.274c1.422-0.443,2.887-0.666,4.396-0.666
			c1.425,0,2.763,0.186,4.016,0.557c1.252,0.371,2.276,0.979,3.074,1.824c0.797,0.845,1.377,1.86,1.739,3.047
			c0.362,1.187,0.479,2.508,0.349,3.963c-0.061,0.685-0.194,1.392-0.398,2.119l-15.358,0.022c-0.076,0.539-0.128,0.97-0.157,1.29
			c-0.087,0.978-0.007,1.875,0.242,2.691c0.248,0.817,0.654,1.51,1.219,2.079c0.563,0.569,1.26,0.989,2.087,1.259
			c0.828,0.271,1.654,0.404,2.479,0.404c0.964,0,2.018-0.108,3.161-0.327C180.853,31.075,182.224,30.703,183.821,30.178z
			 M171.074,21.338l9.804,0.021c0.062-0.698,0.086-1.213,0.074-1.548c-0.013-0.333-0.072-0.715-0.181-1.144
			c-0.108-0.428-0.273-0.807-0.496-1.133c-0.223-0.327-0.491-0.599-0.808-0.817c-0.315-0.218-0.688-0.385-1.117-0.501
			c-0.43-0.117-0.896-0.175-1.399-0.175c-0.586,0-1.164,0.095-1.732,0.284c-0.568,0.188-1.099,0.465-1.593,0.828
			c-0.493,0.364-0.903,0.771-1.229,1.221c-0.327,0.45-0.578,0.882-0.755,1.296C171.465,20.085,171.275,20.641,171.074,21.338z"
        />
      </g>
      <g clipPath="url(#SVGID_2_)">
        <path
          fill="#CF2600"
          d="M187.412,35.146l0.573-3.61l1.896-14.224h-2.599c0.081-0.438,0.189-1.149,0.326-2.133
			s0.232-1.703,0.286-2.156h2.849l0.079-0.875c0.14-1.561,0.309-2.753,0.508-3.577c0.198-0.824,0.493-1.499,0.882-2.024
			s0.911-0.981,1.567-1.368c0.655-0.386,1.36-0.649,2.113-0.788c0.752-0.138,1.52-0.208,2.302-0.208c1.593,0,3.255,0.233,4.986,0.7
			c-0.104,1.634-0.151,3.035-0.144,4.202c-1.297-0.496-2.496-0.744-3.6-0.744c-0.434,0-0.836,0.055-1.209,0.164
			c-0.373,0.11-0.684,0.26-0.931,0.449c-0.248,0.19-0.461,0.437-0.643,0.743c-0.181,0.307-0.317,0.668-0.41,1.084
			c-0.094,0.415-0.163,0.894-0.212,1.434l-0.071,0.809h5.341c-0.172,1.007-0.384,2.437-0.633,4.289h-5.025
			c-0.788,5.807-1.524,11.751-2.209,17.834c-1.267-0.029-2.276-0.044-3.029-0.044C189.641,35.102,188.642,35.116,187.412,35.146z"
        />
        <path
          fill="#CF2600"
          d="M213.203,12.366c1.605,0,3.123,0.252,4.552,0.755c1.429,0.503,2.613,1.247,3.553,2.232
			c0.938,0.984,1.603,2.188,1.992,3.611c0.39,1.422,0.516,2.921,0.375,4.497c-0.148,1.664-0.563,3.269-1.246,4.814
			c-0.683,1.547-1.592,2.885-2.726,4.016c-1.135,1.131-2.498,1.977-4.091,2.538c-1.595,0.562-3.264,0.843-5.01,0.843
			c-1.62,0-3.138-0.252-4.552-0.755c-1.415-0.504-2.596-1.259-3.539-2.266c-0.943-1.006-1.62-2.231-2.028-3.676
			c-0.409-1.444-0.544-2.954-0.403-4.53c0.143-1.604,0.523-3.136,1.143-4.595s1.499-2.758,2.642-3.896
			c1.142-1.137,2.55-2.021,4.228-2.648C209.768,12.68,211.471,12.366,213.203,12.366z M212.801,16.873
			c-1.211,0-2.32,0.311-3.329,0.93c-1.009,0.621-1.802,1.542-2.376,2.768c-0.575,1.226-0.928,2.561-1.057,4.005
			c-0.113,1.27-0.022,2.399,0.272,3.392c0.294,0.992,0.849,1.795,1.663,2.407c0.815,0.613,1.821,0.92,3.019,0.92
			c1.225,0,2.328-0.318,3.311-0.953c0.981-0.634,1.761-1.567,2.337-2.801c0.576-1.232,0.931-2.593,1.063-4.081
			c0.107-1.211,0.024-2.312-0.249-3.304c-0.273-0.992-0.811-1.787-1.614-2.385C215.039,17.173,214.024,16.873,212.801,16.873z"
        />
        <path
          fill="#CF2600"
          d="M221.967,35.146c3.266-3.712,6.592-7.574,9.975-11.586l-1.645-3.026c-0.535-0.986-1.251-2.249-2.146-3.788
			l-2.232-3.855c1.533,0.029,2.759,0.044,3.68,0.044c1.214,0,2.298-0.015,3.248-0.044c0.555,1.041,1.195,2.181,1.92,3.42l1.36,2.421
			l5.021-5.841c0.99,0.029,2.05,0.044,3.182,0.044c1.063,0,2.161-0.015,3.295-0.044l-3.903,4.526
			c-0.886,1.044-2.542,3.016-4.969,5.916l2.824,5.146l3.835,6.666c-1.201-0.029-2.312-0.044-3.332-0.044
			c-1.231,0-2.43,0.015-3.593,0.044c-0.739-1.472-1.485-2.826-2.238-4.061s-1.306-2.196-1.658-2.885
			c-0.629,0.771-1.466,1.752-2.509,2.938c-1.042,1.187-2.17,2.522-3.382,4.007c-1.173-0.029-2.233-0.044-3.184-0.044
			C224.452,35.102,223.269,35.116,221.967,35.146z"
        />
      </g>
      <path
        fill="#CF2600"
        d="M22.479,26.709c0,0,15.751,1.372,8.971,6.879
		c-3.628,2.945-13.178,4.081-19.523,0.988C6.707,32.031,0.725,19.785,24.746,13.722c13.942-3.516,28.262-2.75,20.592-8.638
		c-0.513-0.393-1.286-1.115-0.425-0.802c3.316,1.21,11.234,4.752,9.928,10.619c-0.644,2.892-3.673,6.892-10.39,10.121
		c0,0,3.145,5.906-0.241,9.983c0,0,3.031-8.247-6.377-10.375c0,0,1.223-2.119,3.021-3.694c-2.38-0.371-5.849,0.68-9.456,1.79
		c-3.531,1.087-7.19,2.23-10.088,2.142C20.447,24.842,20.797,26.499,22.479,26.709"
      />
      <path
        fill="#560000"
        d="M29.16,32.352c-2.311,3.653-13.34,3.599-17.233,2.225
		c6.582,3.244,15.977,2.013,19.601-0.933c6.242-5.068-6.519-6.779-8.76-6.906C26.287,27.41,31.137,29.221,29.16,32.352"
      />
      <path
        fill="#560000"
        d="M40.854,20.936c0,0,1.613-0.307,3.597,4.085
		c-1.499-1.853-5.375-1.164-6.618-0.392C37.833,24.63,39.056,21.707,40.854,20.936"
      />
      <path
        fill="#560000"
        d="M40.477,10.814c0,0,6.807-0.052,8.294-2.002c1.489-1.95-1.655-3.972-3.857-4.53
		c0,0-1.028-0.412,0.221,0.641C46.384,5.975,50.692,8.983,40.477,10.814"
      />
      <path
        fill="#560000"
        d="M33.824,24.898c-0.338-0.217-0.271-0.757-0.27-0.763
		c0.004-0.029-0.004-0.059-0.023-0.082c-0.02-0.021-0.046-0.035-0.077-0.037c-1.074-0.025-2.546,0.572-2.604,0.596
		c-0.048,0.02-0.074,0.069-0.063,0.121c0.012,0.05,0.071,0.085,0.11,0.079c1.77-0.151,2.841,0.278,2.851,0.281
		c0.013,0.008,0.028,0.01,0.042,0.01h0.005c0.058-0.002,0.104-0.051,0.102-0.106C33.896,24.949,33.866,24.913,33.824,24.898"
      />
    </g>
  </svg>
);
