export interface IOrganization {
  _id: string;
  client: {
    name: string;
  };
  sites: {
    _id: string;
    name: string;
  }[];
  area: number;

  modules: {
    dabbleCUSTODIAN: IModuleOrgPermission;
    dabbleGROUNDS: IModuleOrgPermission;
    dabbleQC: IModuleOrgPermission;
    dabbleSPACE: IModuleOrgPermission;
    dabbleSHELF: IModuleOrgPermission;
    dabbleEDITOR: IModuleOrgPermission;
  };
}

export interface IModuleOrgPermission {
  enabled: boolean;
  site_ids: string[];
}

export class Organization {
  public id: string;

  public name: string;

  public sites: any[];

  constructor({ _id, client, sites }: IOrganization) {
    this.id = _id;
    this.name = client.name;
    this.sites = sites;
  }

  public hasSite(site_id: string) {
    return !!this.sites.find((val) => val._id === site_id);
  }

  public get label() {
    return this.name;
  }

  public get value() {
    return this.id;
  }
}
