import { makeAutoObservable } from 'mobx';
import { custodialAppState } from '../CustodialAppState';
import { ICustodialZone } from './ICustodialZone';

export class CustodialZone {
  public _id: string;

  public name: string;

  public notes: string;

  public time: {
    daily: number;
  };

  public tech_id: string;

  public color: string;

  public type_id: string;

  public org_id: string;

  public space_ids: string[];

  constructor(params: ICustodialZone) {
    makeAutoObservable(this);

    this._id = params._id;
    this.name = params.name;
    this.notes = params.notes;
    this.time = { daily: params.time?.daily ?? 0 };
    this.tech_id = params.tech_id;
    this.color = params.color;
    this.type_id = params.type_id;
    this.org_id = params.org_id;
    this.space_ids = params.space_ids;
  }

  public get custodian() {
    return this.tech_id
      ? custodialAppState.custodians?.find(
        (x) => x.custodian_id === this.tech_id,
      )
      : undefined;
  }
}
