import React from 'react';

// @ts-ignore
export const InspectorIcon = () => (
  <svg
    id="Capa_1"
    enableBackground="new 0 0 516.097 516.097"
    height="100%"
    viewBox="0 0 516.097 516.097"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="inspectorIconTitle">Inspector Icon</title>
    <desc id="inspectorIconDesc">Inspector Icon</desc>

    <path
      d="m168.094 193.419c-31.06 7.787-62.896-11.059-70.75-42.384-1.008-4.018 1.433-8.092 5.45-9.099 4.022-1.005 8.092 1.433 9.099 5.451 5.81 23.168 29.386 37.292 52.553 31.482 4.018-1.005 8.092 1.434 9.099 5.451 1.007 4.018-1.433 8.091-5.451 9.099z"
      fill="none"
    />
    <path
      d="m331.963 47.95c1.319-26.209-19.564-47.95-45.562-47.95-25.196 0-45.621 20.425-45.621 45.621 0 1.741.107 3.457.297 5.148 5.06 5.105 9.639 10.636 13.701 16.534z"
    />
    <path
      d="m355.658 337.385h7.311c6.994 0 12.252-6.377 10.92-13.243l-18.254-94.086c-7.001-1.534-13.106-5.179-17.733-10.207l-44.309-48.493 53.838 32.897c12.428 7.595 28.714 1.69 33.388-12.105l24.907-73.516c4.029-11.893-2.345-24.799-14.237-28.829-11.896-4.03-24.8 2.346-28.829 14.238l-15.783 46.585-20.769-12.69-50.45 12.649c-4.115 36.64-25.131 70.875-57.899 90.83l-12.904 83.14c-1.047 6.743 4.169 12.831 10.992 12.831h10.204c-.514 12.273-.956 22.845-1.481 35.402l-44.012 106.822c-5.558 13.488.872 28.928 14.36 34.485 13.546 5.583 28.952-.932 34.485-14.36l45.786-111.128c1.174-2.848 1.84-5.88 1.969-8.958.356-8.501 1.11-26.527 1.769-42.264h20.46c3.698 10.083 13.613 37.114 18.296 49.883l19.494 107.134c2.612 14.352 16.359 23.87 30.717 21.259 14.354-2.612 23.871-16.364 21.26-30.717l-19.901-109.368c-.271-1.487-.669-2.948-1.188-4.367-4.288-11.687-7.943-21.653-12.407-33.824z"
    />
    <path
      d="m448.517 91.78c14.962-3.751 24.05-18.922 20.299-33.884-3.757-14.985-18.942-24.046-33.884-20.299l-187.12 46.916c-22.416-40.372-70.674-64.275-120.037-51.899-57.475 14.412-92.511 72.896-78.101 130.373 14.472 57.722 72.882 92.517 130.371 78.102 48.619-12.19 80.622-55.408 81.352-102.392l80.797-20.258 6.483-19.135c6.646-19.615 27.933-30.13 47.55-23.484 11.394 3.86 19.708 12.665 23.38 23.209zm-275.768 120.209c-41.524 10.414-83.562-14.766-93.976-56.298-10.388-41.431 14.867-83.589 56.299-93.977 41.526-10.412 83.562 14.767 93.976 56.298 10.412 41.527-14.766 83.565-56.299 93.977z"
    />
    <path
      d="m164.446 178.869c-23.167 5.81-46.743-8.314-52.553-31.482-1.007-4.018-5.076-6.456-9.099-5.451-4.018 1.007-6.458 5.081-5.45 9.099 7.842 31.278 39.639 50.184 70.75 42.384 4.018-1.007 6.458-5.081 5.45-9.099-1.007-4.018-5.081-6.456-9.098-5.451z"
    />
  </svg>
);
