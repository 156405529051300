import React from 'react';
import './login.css';
import { DabblefoxLogo } from 'client/components/CustomIcons/DabblefoxLogo';

// @ts-ignore
export const LoginWrapper = ({ children }) => (
  <div id="loginBG">
    <div className="centerLogin">
      <div className="loginLogo">
        <DabblefoxLogo />
      </div>
      <div id="loginBox">{children}</div>
    </div>
  </div>
);
