import React from 'react';

// @ts-ignore
export const ToolboxIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 396.549 396.549"
    height="100%"
    width="100%"
  >
    <title id="toolboxIconTitle">Toolbox Icon</title>
    <desc id="toolboxIconDesc">Toolbox Icon</desc>

    <rect x="326.764" y="171.364" width="24.008" height="21.207" />
    <path
      d="M72.049,160.054h46.623v15.636h196.777v-15.636h46.628v15.636h22.799v-41.713c0-15.621-12.662-28.283-28.283-28.283
			H75.858c-15.621,0-28.283,12.662-28.283,28.283v41.713h24.474V160.054z"
    />
    <polygon
      points="164.961,59.302 268.068,59.302 268.068,94.38 276.956,94.38 276.956,41.132 156.073,41.132 156.073,94.38
			164.961,94.38 		"
    />
    <rect x="83.359" y="171.364" width="24.003" height="21.207" />
    <path
      d="M378.102,253.955h-36.89l-18.447,31.949l18.447,31.949h36.89l18.447-31.949L378.102,253.955z M359.654,301.796
			c-8.781,0-15.898-7.117-15.898-15.898c0-8.781,7.117-15.898,15.898-15.898c8.781,0,15.898,7.117,15.898,15.898
			S368.435,301.796,359.654,301.796z"
    />
    <path
      d="M309.699,285.898l24.98-43.259h40.294l0.005-4.557l9.897-11.74v-38.917h-22.799v16.461h-46.628v-16.461H118.676v16.461
			H72.049v-16.461H47.575v38.917l7.588,9.144c11.884,3.973,22.118,11.96,28.687,22.876h90.378
			c8.673-11.812,22.446-18.908,37.381-18.908c18.232,0,34.862,10.767,42.353,27.428c0,0,8.453,20.997,0,38.031
			c-8.453,17.034-24.11,27.428-42.353,27.428c-14.935,0-28.708-7.096-37.381-18.908H83.85
			c-6.154,10.219-15.503,17.905-26.424,22.113l0.061,19.871h317.384v-26.26h-40.197L309.699,285.898z"
    />
    <path
      d="M76.943,302.124H180.47c5.862,11.228,17.597,18.908,31.145,18.908c14.275,0,26.537-8.53,32.031-20.756h-34.642
			l-8.299-14.377l8.299-14.377h34.642c-5.499-12.227-17.756-20.756-32.031-20.756c-13.548,0-25.283,7.68-31.145,18.908H76.943
			c-6.344-15.114-21.279-25.728-38.692-25.728c-17.044,0-31.688,10.184-38.252,24.781h41.365l9.912,17.167l-9.912,17.167H0
			c6.564,14.602,21.207,24.786,38.252,24.786C55.665,327.852,70.6,317.238,76.943,302.124z"
    />
  </svg>
);
