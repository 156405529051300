import React, { useMemo } from 'react';
import { getEditColumn, getPrefixColumn, IDfGridColDef } from 'client/components/generic';

export function useDfGridColumns<T>({
  t,
  columns,
  data,
  processRowUpdate,
  processRowRemove,
  isEditor,
  onRowClickEdit,
  onRowClickDelete,
  onRowClickNavigate,
  rowModesModel,
  setRowModesModel,
}: {
  t: (key: string) => string,
  columns: IDfGridColDef[],
  data: (T & { _id: string })[],
  processRowUpdate?: (updatedRow: any, originalRow: any) => void;
  processRowRemove?: (row?: any) => void;
  isEditor?: boolean,
  onRowClickEdit?: ({ row }: { row?: (T & { _id: string }) }) => void,
  onRowClickDelete?: ({ row }: { row?: (T & { _id: string }) }) => void,
  onRowClickNavigate?: ({ row }: { row?: (T & { _id: string }) }) => string | undefined,
  rowModesModel?: any,
  setRowModesModel?: any,

}) {
  const parsedColumns = useMemo(() => {
    const prefixColumn = getPrefixColumn({
      t,
      data: data || [],
      onRowClickNavigate: onRowClickNavigate!,
      onRowClickEdit: onRowClickEdit!,
      onRowClickDelete: onRowClickDelete!,
    });
    const editColumn = getEditColumn({
      t,
      data: data || [],
      rowModesModel,
      setRowModesModel,
      processRowUpdate,
      processRowRemove,
    });
    // const deleteColumn = getDeleteColumn({
    //   t,
    //   data: data || [],
    //   rowModesModel,
    //   setRowModesModel,
    //   processRowRemove: processRowUpdate!,
    // });

    const newColumns = columns.slice();

    if (onRowClickEdit || onRowClickDelete || onRowClickNavigate) newColumns.unshift(prefixColumn);
    // if (onRowClickNavigate) columns.unshift(navigateColumn);
    if (processRowUpdate || processRowRemove) newColumns.push(editColumn);
    // if (processRowRemove) newColumns.push(deleteColumn);
    if (!processRowUpdate) {
      newColumns.forEach((column) => {
        if (column.headerName) {
          column.renderHeader = () => React.createElement('strong', {}, column.headerName);
        }

        column.editable = false;
      });
    }

    return newColumns;
  }, [columns, processRowUpdate, isEditor, rowModesModel]);

  return parsedColumns;
}
