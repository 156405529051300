import React, { useState } from 'react';
import { useUserMutation } from 'client/utilities/hooks';
import { useTranslation } from 'react-i18next';
import { LoginInput } from './loginInput';

interface ILoginComponent {
  email: string;
  setEmail: (v: string) => void;
  setPage: (v: string) => void;
}

export const LoginComponent = ({
  email,
  setEmail,
  setPage,
}: ILoginComponent) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { mutation } = useUserMutation();

  function handleSubmit(e: any) {
    e.preventDefault();
    e.stopPropagation();

    mutation
      .mutateAsync({ method: 'login', email, password })
      .then(() => window.location.reload())
      .catch((err) => {
        setError(
          t(
            'Could not log in, please check your email and password and try again',
          ),
        );
      });
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <LoginInput
          type="email"
          handleChange={(v) => {
            setEmail(v);
            setError('');
          }}
          name="email"
          autocomplete="email"
          label={t('Email Address')}
          value={email}
        />
        <LoginInput
          type="password"
          handleChange={(v) => {
            setPassword(v);
            setError('');
          }}
          autocomplete="current-password"
          name="password"
          label={t('Password')}
          value={password}
          error={error}
        />
        <div style={{ textAlign: 'center' }}>
          <button className="btn btn-default" type="submit">
            {t('Log In')}
          </button>
        </div>
        <small>
          <a href="#" onClick={() => setPage('forgotPassword')}>
            {t('Forgot Password?')}
          </a>
        </small>
      </form>
    </div>
  );
};
