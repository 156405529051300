import { makeAutoObservable } from 'mobx';
import { CustodialProfile } from './custodialProfile';
import { CustodialTask } from './custodialTask';
import { CustodialTeam } from './custodialTeam';
import { CustodialZone } from './custodialZone';
import { CustodialRoomUse } from './custodialRoomuse';
import { nameSort } from '../../functions/nameSort';
import { CustodialCustodian } from './custodialCustodian';
import { Site } from '../location/site';

export interface ICustodialScenario {
  _id: string;
  name: string;
  isDefault: boolean;
}

export class CustodialScenario {
  public profiles: CustodialProfile[] = [];

  public tasks: CustodialTask[] = [];

  public teams: CustodialTeam[] = [];

  public zones: CustodialZone[] = [];

  public roomUses: CustodialRoomUse[] = [];

  public custodians: CustodialCustodian[] = [];

  public readonly id: string;

  public name: string;

  public isDefault: boolean;

  public readonly site: Site;

  constructor({ _id, name, isDefault }: ICustodialScenario, site: Site) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.id = _id;
    this.name = name;
    this.isDefault = isDefault;
    this.site = site;
  }

  public fetch() {
    // const site_id = this.site.id;
    // const scenario_id = this.id;
    //
    // // @ts-ignore
    // return Promise.all<
    //   [
    //     ICustodialZone[],
    //     ICustodialCustodian[],
    //     ICustodialProfile[],
    //     ICustodialRoomUse[],
    //     ICustodialTask[],
    //     ICustodialTeam[],
    //   ]
    // >([
    //   ApiCalls.getCustodialZones({ site_id, scenario_id }),
    //   ApiCalls.getCustodialCustodians({ site_id, scenario_id }),
    //   ApiCalls.getCustodialProfiles({ site_id, scenario_id }),
    //   ApiCalls.getCustodialRoomUses({ site_id, scenario_id }),
    //   ApiCalls.getCustodialTasks({ site_id, scenario_id }),
    //   ApiCalls.getCustodialTeams({ site_id, scenario_id }),
    // ]).then(([zones, custodians, profiles, roomUses, tasks, teams]) => {
    //   const newTeams = teams.map((val) => new CustodialTeam(val, this));
    //   const newCustodians = custodians.map(
    //     (val) => new CustodialCustodian(val, this),
    //   );
    //
    //   this.setZones(
    //     zones.map(
    //       (val) => new CustodialZone(val, newCustodians, newTeams, this),
    //     ),
    //   );
    //   this.setProfiles(profiles.map((val) => new CustodialProfile(val, this)));
    //   this.setCustodians(newCustodians);
    //   this.setRoomUses(roomUses.map((val) => new CustodialRoomUse(val, this)));
    //   this.setTasks(tasks.map((val) => new CustodialTask(val, this)));
    //   this.setTeams(newTeams);
    // });
  }

  public setProfiles(profiles: CustodialProfile[]) {
    this.profiles = nameSort(profiles);
  }

  public setTasks(tasks: CustodialTask[]) {
    this.tasks = nameSort(tasks);
  }

  public setTeams(teams: CustodialTeam[]) {
    this.teams = nameSort(teams);
  }

  public setZones(zones: CustodialZone[]) {
    this.zones = nameSort(zones);
  }

  public setRoomUses(roomUses: CustodialRoomUse[]) {
    this.roomUses = roomUses;
  }

  public setCustodians(custodians: CustodialCustodian[]) {
    this.custodians = nameSort(custodians);
  }
}
