import { makeAutoObservable, runInAction } from 'mobx';
import { User } from './classes/auth/user';
import ApiCalls from './apiCalls';
import { BaseModule } from './modules/base';
import { ModuleCustodian } from './modules/ModuleCustodian';
import { ModuleQC } from './modules/ModuleQC';
import { UiState } from './states/uiState';
import { LocationState } from './states/locationState';
import { ModuleEditor } from './modules/ModuleEditor';
import { ModuleAdmin } from './modules/ModuleAdmin';
import { ModuleSpace } from './modules/ModuleSpace';
import { ModuleShelf } from './modules/ModuleShelf';
import { ErrorState } from './states/errorState';
import { SuperAdminState } from './states/superAdminState';
import { ModuleSuperAdmin } from './modules/ModuleSuperAdmin';
import { ModuleEntrance } from './modules/ModuleEntrance';

export class ParentAppState {
  public loggedIn?: boolean;

  public loading: boolean = true;

  public noApi?: boolean;

  public user?: User;

  public modules: BaseModule[] = [];

  public currentModule?: BaseModule;

  public uiState: UiState = new UiState(this);

  public errorState: ErrorState = new ErrorState();

  public locationState: LocationState = new LocationState(this);

  public superAdminState?: SuperAdminState;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.initialize();
  }

  public initialize() {
    this.setLoading(true);
    this.getUser()
      .then(() => {
        this.uiState.loadUiState();
        return this.locationState.fetchSites();
      })
      .catch((err) => {
        runInAction(() => {
          if (err.status) {
            this.loggedIn = false;
          } else {
            this.noApi = true;
          }
        });
        if (err) console.error(err);
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  get ready(): boolean {
    return true;
  }

  private getUser() {
    return new Promise<void>((resolve, reject) => {
      const token = window.localStorage.getItem('token');
      if (token) {
        ApiCalls.setToken({ token });
        ApiCalls.getUser()
          .then((user) => {
            this.setUser(new User({ ...user, site_id: '' }));
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        reject({ status: 'No token' });
      }
    });
  }

  public setModuleByBaseRoute(baseRoute: string) {
    this.currentModule = this.modules.find((module) => module.baseRoute === baseRoute)
      || new ModuleEntrance();
    document.title = this.currentModule ? this.currentModule.name : 'Dabblefox';

    this.currentModule?.initialize();
  }

  public get params() {
    const pathSplit = window.location.pathname.split('/');
    return {
      module: pathSplit[1],
      page: pathSplit[2],
      sub_page: pathSplit[3],
      site_id: pathSplit[4],
      start_date: +pathSplit[5],
      end_date: +pathSplit[6],
      scenario_id: pathSplit[7],
      bldg_id: pathSplit[8],
      space_id: pathSplit[9],
    };
  }

  public setLoading(state: boolean) {
    this.loading = state;
  }

  public setNoApi(state: boolean) {
    this.noApi = state;
  }

  public setQuery(query: { [key: string]: string | number | boolean }) {
    const stringQuery = Object.keys(query)
      .map((val) => `${val}=${query[val]}`)
      .join('&');
  }

  public setUser(user: User) {
    this.user = user;
    this.loggedIn = true;

    this.modules = Object.keys(user.permissions.modules)
      .filter(
        (moduleName: string) =>
          // return (user.permissions.superAdmin || user.permissions.modules[moduleName].site_ids.length); //TODO: This should be used, after I add site_ids to users
          user.permissions.superAdmin
          || user.permissions.modules[moduleName].read
          || user.permissions.modules[moduleName].write,
      )
      .concat(
        user.permissions.admin || user.permissions.superAdmin
          ? ['dabbleADMIN']
          : [],
      )
      .concat(user.permissions.superAdmin ? ['superADMIN'] : [])
      .reduce((acc: BaseModule[], moduleName: string) => {
        switch (moduleName) {
          case 'superADMIN':
            acc.push(new ModuleSuperAdmin());
            break;
          case 'dabbleADMIN':
            acc.push(new ModuleAdmin());
            break;
          case 'dabbleCUSTODIAN':
            acc.push(new ModuleCustodian());
            break;
          case 'dabbleQC':
            acc.push(new ModuleQC());
            break;
          case 'dabbleEDITOR':
            acc.push(new ModuleEditor());
            break;
          case 'dabbleSHELF':
            acc.push(new ModuleShelf());
            break;
          case 'dabbleSPACE':
            acc.push(new ModuleSpace());
            break;
        }
        return acc;
      }, []);

    if (this.user.permissions.superAdmin) {
      runInAction(() => {
        this.superAdminState = new SuperAdminState(this);
      });
    }
  }
}

export const parentAppState = new ParentAppState();
