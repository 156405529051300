import { getObjectFromQuery } from 'client/utilities/functions';
import { NavigateFunction } from 'react-router';
import { Building } from '../location/building';
import { EditorRoomUse } from './editorRoomUse';
import { EditorFlooring } from './editorFlooring';
import { EditorState } from '../../states/editorState';
import ApiCalls from '../../apiCalls';

export interface IEditorReportData {
  _id: string;
  name: string;
  number: string;
  sumWeighted: number;
  sumWeight: number;
  inspectionCount: number;
  roomCount: number;
  uniqueRoomCount: number;
  pointCount: number;
  count: number;
  last: number;
  value: number;
  adjuster_ids: string[];
  points: IEditorReportDataPoint[];
}

interface IEditorReportDataPoint {
  _id: string;
  bldg: any;
  space: any;
  roomUse: any;
  flooring: any;
  area: number;
  stations: number;
}

interface IEditorReportSettings {
  reportType?: 'buildings' | 'spaces' | 'roomUse' | 'flooring';
  chartType?:
    | 'table'
    | 'tableDaily'
    | 'tableWeekly'
    | 'tableMonthly'
    | 'lineChartDaily'
    | 'lineChartWeekly'
    | 'lineChartMonthly'
    | 'barChartAverage'
    | 'barChartAverage'
    | 'stackedBarChartByCount'
    | 'stackedBarChartFullWidth'
    | 'pieChartByCount'
    | 'pieChartByRating';
  sort?:
    | 'areaHigh'
    | 'areaLow'
    | 'stationHigh'
    | 'stationLow'
    | 'nameHigh'
    | 'nameLow';
  count?: 'roomCount';
  buildings?: string[];
  roomUses?: string[];
  floorings?: string[];
  stations?: number[];
  area?: number[];
  simplifyPoints?: boolean;
}

class EditorReportType {
  label: string;

  value:
    | 'buildings'
    | 'spaces'
    | 'inspections'
    | 'categories'
    | 'roomUse'
    | 'flooring'
    | 'custodians'
    | 'inspectors'
    | 'profiles'
    | 'tasks';

  constructor({
    label,
    value,
  }: {
    label: string;
    value:
      | 'buildings'
      | 'spaces'
      | 'inspections'
      | 'categories'
      | 'roomUse'
      | 'flooring'
      | 'custodians'
      | 'inspectors'
      | 'profiles'
      | 'tasks';
  }) {
    this.label = label;
    this.value = value;
  }

  static options() {
    return [
      new EditorReportType({ label: 'Buildings', value: 'buildings' }),
      new EditorReportType({ label: 'Spaces', value: 'spaces' }),
      new EditorReportType({ label: 'Room Use', value: 'roomUse' }),
      new EditorReportType({ label: 'Floorings', value: 'flooring' }),
    ];
  }
}

class EditorChartType {
  label: string;

  value: 'tableDefault';

  constructor({ label, value }: { label: string; value: 'tableDefault' }) {
    this.label = label;
    this.value = value;
  }

  static options() {
    return [new EditorChartType({ label: 'Table', value: 'tableDefault' })];
  }
}

class EditorSortType {
  label: string;

  value:
    | 'areaHigh'
    | 'areaLow'
    | 'stationHigh'
    | 'stationLow'
    | 'nameHigh'
    | 'nameLow';

  static options() {
    return [
      new EditorSortType({
        label: 'Area - Highest to Lowest',
        value: 'areaHigh',
      }),
      new EditorSortType({
        label: 'Area - Lowest to Highest',
        value: 'areaLow',
      }),
      new EditorSortType({
        label: 'Station Count - Highest to Lowest',
        value: 'stationHigh',
      }),
      new EditorSortType({
        label: 'Station Count - Lowest to Highest',
        value: 'stationLow',
      }),
      new EditorSortType({
        label: 'Name - Highest to Lowest',
        value: 'nameHigh',
      }),
      new EditorSortType({
        label: 'Name - Lowest to Highest',
        value: 'nameLow',
      }),
    ];
  }

  constructor({
    label,
    value,
  }: {
    label: string;
    value:
      | 'areaHigh'
      | 'areaLow'
      | 'stationHigh'
      | 'stationLow'
      | 'nameHigh'
      | 'nameLow';
  }) {
    this.label = label;
    this.value = value;
  }
}

class EditorCountType {
  label: string;

  value: 'roomCount';

  static options() {
    return [new EditorCountType({ label: 'Unique Rooms', value: 'roomCount' })];
  }

  constructor({ label, value }: { label: string; value: 'roomCount' }) {
    this.label = label;
    this.value = value;
  }
}

class EditorReportSettings {
  public reportType: EditorReportType;

  public chartType: EditorChartType;

  public buildings: Building[] = [];

  public roomUses: EditorRoomUse[] = [];

  public floorings: EditorFlooring[] = [];

  public simplifyPoints: boolean = true;

  sort: EditorSortType;

  limit: number = 0;

  count: EditorCountType;

  color: string = 'ff0000';

  public readonly reportTypeOptions: EditorReportType[];

  public readonly chartTypeOptions: EditorChartType[];

  public readonly sortOptions: EditorSortType[];

  public readonly countTypeOptions: EditorCountType[];

  public readonly report: EditorReport;

  constructor(
    options: IEditorReportSettings,
    report: EditorReport,
    editorState: EditorState,
  ) {
    function getById(ids: string[] | undefined, values: any[]) {
      return ids ? values.filter((val) => ids.indexOf(val.id) > -1) : [];
    }

    this.reportTypeOptions = EditorReportType.options();
    this.chartTypeOptions = EditorChartType.options();
    this.sortOptions = EditorSortType.options();
    this.countTypeOptions = EditorCountType.options();

    this.reportType = this.reportTypeOptions.find(
      (val) => val.value === (options.reportType || 'buildings'),
    )!;
    this.chartType = this.chartTypeOptions.find(
      (val) => val.value === (options.chartType || 'tableDefault'),
    )!;
    this.sort = this.sortOptions.find(
      (val) => val.value === (options.sort || 'valueHigh'),
    )!;
    this.count = this.countTypeOptions.find(
      (val) => val.value === (options.count || 'inspectionCount'),
    )!;
    this.buildings = getById(
      options.buildings,
      editorState.site?.buildings ?? [],
    );
    this.roomUses = getById(
      options.roomUses,
      editorState.site?.editorState.roomUses ?? [],
    );
    this.floorings = getById(
      options.floorings,
      editorState.site?.editorState.floorings ?? [],
    );

    this.simplifyPoints = options.simplifyPoints !== undefined ? options.simplifyPoints : true;

    this.report = report;
  }

  set(field: string, value: any) {
    switch (field) {
      case 'reportType':
        this.reportType = value;
        break;
      case 'chartType':
        this.chartType = value;
        break;
      case 'buildings':
        this.buildings = value || [];
        break;
      case 'roomUses':
        this.roomUses = value || [];
        break;
      case 'floorings':
        this.floorings = value || [];
        break;
      case 'simplifyPoints':
        this.simplifyPoints = value;
        break;
      case 'sort':
        this.sort = value;
        break;
      case 'limit':
        this.limit = value;
        break;
      case 'count':
        this.count = value;
        break;
      case 'color':
        this.color = value;
        break;
    }
  }

  private createQuery() {
    const obj = this.toObject();

    const query = Object.keys(obj)
      .map((val) => (
        `${val}=${
          // @ts-ignore
          Array.isArray(obj[val]) ? obj[val].join(',') : obj[val]
        }`))
      .concat([
        `reportType=${this.reportType?.value}`,
        `chartType=${this.chartType?.value}`,
        `count=${this.count?.value}`,
        `sort=${this.sort?.value}`,
        `limit=${this.limit}`,
        `color=${this.color}`,
      ])
      .join('&');

    return query;
  }

  public setQuery(navigate: NavigateFunction) {
    navigate(`${location.pathname}?${this.createQuery()}`);
    this.report.fetchData();
  }

  static queryToSettings(searchString: string) {
    const query = getObjectFromQuery(searchString);
    const newQuery: { [key: string]: string | string[] } = {};
    Object.keys(query).forEach((key) => {
      switch (key) {
        case 'reportType':
          newQuery.reportType = query[key];
          break;
        case 'chartType':
          newQuery.chartType = query[key];
          break;
        case 'bldg_ids':
          newQuery.buildings = query[key].split(',').filter((val) => val);
          break;
        case 'custodian_ids':
          newQuery.custodians = query[key].split(',').filter((val) => val);
          break;
        case 'inspector_ids':
          newQuery.inspectors = query[key].split(',').filter((val) => val);
          break;
        case 'category_ids':
          newQuery.categories = query[key].split(',').filter((val) => val);
          break;
        case 'rating_ids':
          newQuery.ratings = query[key].split(',').filter((val) => val);
          break;
        case 'zone_ids':
          newQuery.zones = query[key].split(',').filter((val) => val);
          break;
        case 'team_ids':
          newQuery.teams = query[key].split(',').filter((val) => val);
          break;
        case 'profile_ids':
          newQuery.profiles = query[key].split(',').filter((val) => val);
          break;
        case 'task_ids':
          newQuery.tasks = query[key].split(',').filter((val) => val);
          break;
        case 'roomUse_ids':
          newQuery.roomUses = query[key].split(',').filter((val) => val);
          break;
        case 'flooring_ids':
          newQuery.floorings = query[key].split(',').filter((val) => val);
          break;
        // case "simplifyPoints": this.set("simplifyPoints", query[key]); break;
        case 'sort':
          newQuery.sort = query[key];
          break;
        case 'limit':
          newQuery.limit = query[key];
          break;
        case 'color':
          newQuery.color = query[key];
          break;
        case 'count':
          newQuery.count = query[key];
          break;
      }
    });

    console.log('New Query', newQuery);
    return newQuery;
  }

  public toObject() {
    const obj = {
      bldg_ids: this.buildings.map((val) => val.id),
      roomUse_ids: this.roomUses.map((val) => val.id),
      flooring_ids: this.floorings.map((val) => val.id),
    };

    return Object.keys(obj).reduce(
      (acc: { [key: string]: string | string[] }, val: string) => {
        // @ts-ignore
        const objValue = obj[val];

        if (objValue.length) {
          acc[val] = objValue;
        }
        return acc;
      },
      {},
    );
  }
}

export interface IEditorReport {
  name: string;
  settings?: IEditorReportSettings;
}

export default class EditorReport {
  public name: string;

  settings: EditorReportSettings;

  data: IEditorReportData[] = [];

  public ready: boolean = false;

  public id: string = btoa(Math.random().toString())
    .replace('=', '')
    .replace('=', '');

  private readonly _editorState: EditorState;

  constructor({ name, settings }: IEditorReport, editorState: EditorState) {
    this.name = name;
    this.settings = new EditorReportSettings(settings ?? {}, this, editorState);

    this._editorState = editorState;
  }

  public setName(name: string) {
    this.name = name;
  }

  public setReady(ready: boolean) {
    this.ready = ready;
  }

  public setData(data: IEditorReportData[]) {
    this.data = data;
  }

  public get sortedData() {
    return this.sortData(
      this.data,
      this.settings.sort,
      this.settings.limit,
      this.settings.count,
    );
  }

  public fetchSettingsFromQuery(search: string) {
    this.settings = new EditorReportSettings(
      EditorReportSettings.queryToSettings(search),
      this,
      this.editorState,
    );
    this.fetchData();
  }

  public fetchData() {
    this.ready = false;
    ApiCalls.getEditorReport({
      site_id: this._editorState.site.id,
      query: this.settings.toObject(),
      simplifyPoints: this.settings.simplifyPoints,
      reportType: this.settings.reportType.value,
      startDate: this._editorState.site.appState.params.start_date,
      endDate: this._editorState.site.appState.params.end_date,
    })
      .then(({ data }) => {
        this.setData(data);
        this.setReady(true);
      })
      .then(() => {});
  }

  private sortData(
    data: IEditorReportData[],
    sort: EditorSortType,
    limit: number,
    count: EditorCountType,
  ) {
    switch (sort?.value) {
      case 'areaHigh':
        return data
          .slice()
          .sort((a: any, b: any) => b.area - a.area)
          .slice(0, limit || undefined);
      case 'areaLow':
        return data
          .slice()
          .sort((a: any, b: any) => a.area - b.area)
          .slice(0, limit || undefined);
      case 'stationHigh':
        return data
          .slice()
          .sort((a: any, b: any) => b.stations - a.stations)
          .slice(0, limit || undefined);
      case 'stationLow':
        return data
          .slice()
          .sort((a: any, b: any) => a.stations - b.stations)
          .slice(0, limit || undefined);
      case 'nameHigh':
        return data
          .slice()
          .sort((a: any, b: any) => b.name.localeCompare(a.name, 'en', { numeric: true }))
          .slice(0, limit || undefined);
      case 'nameLow':
        return data
          .slice()
          .sort((a: any, b: any) => a.name.localeCompare(b.name, 'en', { numeric: true }))
          .slice(0, limit || undefined);
      default:
        return data.slice(0, limit || undefined);
    }
  }

  public get editorState() {
    return this._editorState;
  }
}
