import { makeAutoObservable } from 'mobx';
import { ISite, Site } from '../classes/location/site';
import ApiCalls from '../apiCalls';
import { ParentAppState, parentAppState } from '../parentAppState';

export class LocationState {
  public ready: boolean = false;

  public sites: Site[] = [];

  public currentSite?: Site;

  public readonly appState: ParentAppState;

  constructor(appState: ParentAppState) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.appState = appState;
  }

  public fetchSites() {
    return ApiCalls.getSites()
      .then((sites: ISite[]) => {
        console.log('SITES', sites);
        this.setSites(sites.map((site) => new Site(site, parentAppState)));
      })
      .catch((err) => {
        console.error(err);
        this.setReady(true);
      });
  }

  private setSites(sites: Site[]) {
    this.sites = sites.sort((a, b) => a.searchName.localeCompare(b.searchName, 'en', { numeric: true }));
    if (!this.currentSite) {
      if (window.localStorage.getItem('lastSite_id')) {
        const nextSite = sites.find(
          (site) => site.id === window.localStorage.getItem('lastSite_id'),
        ) || sites[0];
        this.setCurrentSite(nextSite);
      } else {
        this.setCurrentSite(sites[0]);
      }
    }
    this.setReady(true);
  }

  public setCurrentSite(site: Site) {
    this.currentSite = site;
    window.localStorage.setItem('lastSite_id', site.id);
    site.initialize();
  }

  public setCurrentSiteBySite_id(site_id: string) {
    const site = this.sites.find((site) => site.id === site_id);
    if (site && this.currentSite !== site) this.setCurrentSite(site);
  }

  setReady(state: boolean) {
    this.ready = state;
    console.log('READY');
  }
}
