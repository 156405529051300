import { makeAutoObservable } from 'mobx';
import { EditorFlooring, IEditorFlooring } from '../classes/editor/editorFlooring';
import { EditorRoomUse, IEditorRoomUse } from '../classes/editor/editorRoomUse';
import { nameSort } from '../functions/nameSort';
import ApiCalls from '../apiCalls';
import { Site } from '../classes/location/site';
import EditorReport from '../classes/editor/editorReport';

export class EditorState {
  public floorings: EditorFlooring[] = [];

  public roomUses: EditorRoomUse[] = [];

  private readonly _site: Site;

  public currentReport: EditorReport = new EditorReport(
    { name: '', settings: {} },
    this,
  );

  constructor(site: Site) {
    makeAutoObservable(this, {}, { autoBind: true });
    this._site = site;
  }

  public fetch() {
    const site_id = this._site.id;
    return Promise.all<any>([
      ApiCalls.getEditorFlooring({ site_id }),
      ApiCalls.getEditorRoomUses({ site_id }),
    ]).then(([floorings, roomUses]) => {
      this.setFlooring(
        (floorings as IEditorFlooring[]).map(
          (flooring) => new EditorFlooring(flooring),
        ),
      );
      this.setRoomUses(
        (roomUses as IEditorRoomUse[]).map(
          (roomUse) => new EditorRoomUse(roomUse),
        ),
      );
    });
  }

  public setFlooring(flooring: EditorFlooring[]) {
    this.floorings = nameSort(flooring);
  }

  public setRoomUses(roomUses: EditorRoomUse[]) {
    this.roomUses = nameSort(roomUses);
  }

  public get site() {
    return this._site;
  }
}
