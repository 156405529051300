export interface IEditorRoomUse {
  _id: string;
  name: string;
  code?: number | string;
  color: string;
}

export class EditorRoomUse {
  public readonly id: string;

  public name: string;

  public code: string;

  public color: string;

  constructor({
    _id, name, code, color,
  }: IEditorRoomUse) {
    this.id = _id;
    this.name = name;
    this.code = code?.toString() || '';
    this.color = color;
  }

  public get label() {
    return [this.name, this.code]
      .filter((val) => val !== null && val !== undefined && val !== '')
      .join(' - ');
  }

  public get value() {
    return this.id;
  }
}
