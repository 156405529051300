import React from 'react';

interface ILoginInput {
  type: string;
  value: string;
  name: string;
  label: string;
  handleChange: (v: string) => void;
  error?: string;
  autocomplete?: string;
  helperText?: string;
}

export const LoginInput = ({
  type,
  value,
  name,
  label,
  handleChange,
  error,
  autocomplete,
  helperText,
}: ILoginInput) => {
  const classes = `form-group ${error ? 'has-error' : ''}`;
  const errorText = error ? <small>{error}</small> : '';

  return (
    <div className={classes}>
      <label>{label}</label>
      <div style={{ display: 'flex' }}>
        <input
          type={type}
          onChange={(e) => handleChange(e.currentTarget.value ?? '')}
          value={value}
          name={name}
          autoComplete={autocomplete}
        />
      </div>
      <div className="error">{errorText ?? helperText}</div>
    </div>
  );
};
