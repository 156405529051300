import React, { useCallback, useEffect, useState } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeleteButton } from 'client/components/generic/DeleteButton';
import { useConfirm } from 'material-ui-confirm';

interface IEditButton {
  isEditor: boolean;
  onClick?: () => void;
  fullWidth?: boolean;
  disabled?: boolean;
  text: string;
  style?: { [key: string]: string };
  autoFocus?: boolean;
  confirmationText?: string;
  hotKeys?: string[];
  isEditing?: boolean;
  onDelete?: () => void;
  saveChanges?: () => void;
  cancelChanges?: () => void;
  isSubmit?: boolean;
}

export const EditButton = ({
  isEditor,
  disabled,
  onClick,
  fullWidth,
  text,
  style,
  autoFocus,
  confirmationText,
  hotKeys,
  isEditing,
  onDelete,
  saveChanges,
  cancelChanges,
  isSubmit,
}: IEditButton) => {
  const { t } = useTranslation();
  const [keysDown, setKeysDown] = useState<string[]>([]);
  const confirm = useConfirm();

  useEffect(() => {
    if (hotKeys) {
      const keysDownSet = new Set(keysDown);
      const hotKeysSet = new Set(hotKeys ?? []);
      const combinedSet = new Set(keysDown.concat(hotKeys));
      if (
        onClick
        && keysDownSet.size === hotKeysSet.size
        && keysDownSet.size === combinedSet.size
      ) {
        onClick();
      }
    }
  }, [keysDown, hotKeys]);

  const keyDown = useCallback(
    (event: any) => {
      setKeysDown(Array.from(new Set([...keysDown, event.key])));
    },
    [keysDown],
  );

  const keyUp = useCallback(
    (event: any) => {
      setKeysDown(Array.from(new Set(keysDown.filter((x) => x !== event.key))));
    },
    [keysDown],
  );

  useEffect(() => {
    document.addEventListener('keydown', keyDown, false);

    return () => {
      document.removeEventListener('keydown', keyDown, false);
    };
  }, [keyDown]);

  useEffect(() => {
    document.addEventListener('keyup', keyUp, false);

    return () => {
      document.removeEventListener('keyup', keyUp, false);
    };
  }, [keyUp]);

  return isEditing ? (
    <div style={{ display: 'flex', gap: '1em' }}>
      {onDelete && (
        <DeleteButton isEditor={isEditor} onClick={onDelete} text={t('Delete')} />
      )}
      <ButtonGroup>
        {cancelChanges && (
          <Button
            type="button"
            key="saveChanges"
            color="primary"
            onClick={cancelChanges}
          >
            {t('Cancel')}
          </Button>
        )}
        {saveChanges && (
          <Button
            type={isSubmit ? 'submit' : undefined}
            key="cancelChanges"
            color="success"
            onClick={saveChanges}
          >
            {t('Save')}
          </Button>
        )}
      </ButtonGroup>
    </div>
  ) : (
    <Button
      className="no-print"
      autoFocus={autoFocus}
      size="small"
      onClick={() => {
        if (confirmationText) {
          confirm({ description: confirmationText })
            .then(() => {
              onClick?.();
            });
        } else {
          onClick?.();
        }
      }}
      type={isSubmit ? 'submit' : 'button'}
      disabled={disabled}
      style={{
        display: isEditor ? '' : 'none',
        width: fullWidth ? '100%' : undefined,
        backgroundColor: 'orange',
        ...(style || {}),
      }}
      variant="contained"
    >
      {text}
    </Button>
  );
};
