export function getObjectFromQuery(query?: string) {
  return query
    ? query
      .replace('?', '')
      .split('&')
      .reduce((acc: { [key: string]: string }, val: string) => {
        const split = val.split('=');
        if (split.length === 2) {
          acc[split[0]] = split[1];
        }
        return acc;
      }, {})
    : {};
}
