import React from 'react';

// @ts-ignore
export const CustodiansIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 511.997 511.997"
    height="100%"
    width="100%"
  >
    <title id="custodiansIconTitle">Custodians Icon</title>
    <desc id="custodiansIconDesc">Custodians Icon</desc>

    <circle cx="125.57" cy="46.999" r="44.597" />
    <path
      d="M492.757,420.892h-18.65c2.504-1.391,4.357-3.85,4.892-6.833l18.191-101.566c0.512-2.855-0.268-5.789-2.13-8.013
			c-1.861-2.225-4.612-3.509-7.512-3.509h-97.839c-2.9,0-5.651,1.286-7.512,3.509c-1.861,2.224-2.641,5.159-2.13,8.013
			l18.191,101.566c0.534,2.984,2.388,5.442,4.892,6.833h-31.738V252.537c0-8.214-6.659-14.873-14.873-14.873h-35.157L309.113,60.245
			c-0.452-6.533-6.1-11.466-12.647-11.012c-6.534,0.452-11.465,6.115-11.013,12.647l12.157,175.782h-28.005l-33.476-136.848
			c-1.556-6.361-7.973-10.257-14.337-8.7c-6.362,1.556-10.257,7.975-8.701,14.335l14.514,59.333
			c-1.331-0.197-2.702-0.264-4.099-0.186l-80.194,4.389l6.076-22.444c4.347-16.06-5.148-32.605-21.208-36.952l-51.86-14.035
			c-16.06-4.347-32.605,5.148-36.952,21.209C39.09,118.788,9.484,228.157,1.143,258.974c-1.414,5.224-1.523,10.745-0.29,16.014
			c3.688,15.764,12.612,53.74,24.903,104.871L12.528,482.422c-1.663,12.892,7.44,24.69,20.332,26.354
			c12.929,1.662,24.695-7.473,26.352-20.332L72.998,381.56c0.368-2.856,0.21-5.755-0.468-8.553l-21.973-92.082
			c0,0,29.226,51.567,55.357,97.435L94.549,482.888c-1.405,12.922,7.931,24.537,20.854,25.942
			c12.897,1.407,24.535-7.912,25.942-20.854l12.189-112.107c0.535-4.926-0.496-9.895-2.951-14.2l-40.112-70.366l8.179-30.216
			l-8.163-1.243c-18.407-2.805-29.445-17.526-31.348-26.687L62.346,152.27l35.785,76.944c2.859,6.145,8.597,10.436,15.278,11.455
			l79.805,12.158c10.978,1.675,21.33-5.852,23.018-16.931c1.678-11.018-5.883-21.334-16.931-23.018
			c-7.326-1.116-61.806-9.417-69.096-10.527l-32.407-69.679c1.937,2.344,41.019,49.648,42.646,51.618l61.778,9.412
			c9.215,1.404,17.201,5.903,23.08,12.27l0.407-0.022c4.042-0.221,7.732-1.628,10.776-3.846l8.699,35.561l-9.63,0.036
			c-0.978,9.157-4.961,16.752-11.218,23.051v61.243h-43.791c-6.316,0-11.83,4.767-12.238,11.069
			c-0.447,6.906,5.023,12.647,11.833,12.647h44.197v121.811c0,6.55,5.31,11.858,11.858,11.858h15.595
			c0.065-4.104,0.665-8.077,1.734-11.858c5.508-19.494,23.444-33.827,44.677-33.827c21.233,0,39.17,14.332,44.677,33.827
			c1.069,3.782,1.669,7.755,1.734,11.858h49.474c0.065-4.104,0.665-8.077,1.734-11.858c5.508-19.494,23.445-33.827,44.677-33.827
			s39.171,14.332,44.677,33.827c1.069,3.782,1.669,7.755,1.734,11.858h13.23c6.548,0,11.858-5.309,11.858-11.858v-27.385
			C512.001,429.507,503.385,420.892,492.757,420.892z"
    />
    <path
      d="M298.205,450.652c-16.277,0-29.471,13.195-29.471,29.471s13.194,29.471,29.471,29.471
			c16.276,0,29.471-13.195,29.471-29.471C327.676,463.846,314.481,450.652,298.205,450.652z M298.205,490.621
			c-5.798,0-10.499-4.701-10.499-10.498c0-5.797,4.701-10.498,10.499-10.498s10.498,4.701,10.498,10.498
			C308.703,485.921,304.003,490.621,298.205,490.621z"
    />
    <path
      d="M440.501,450.652c-16.276,0-29.471,13.195-29.471,29.471s13.194,29.471,29.471,29.471
			c16.277,0,29.471-13.195,29.471-29.471C469.973,463.846,456.778,450.652,440.501,450.652z M440.501,490.621
			c-5.798,0-10.498-4.701-10.498-10.498c0-5.797,4.701-10.498,10.498-10.498c5.798,0,10.499,4.701,10.499,10.498
			C451,485.921,446.3,490.621,440.501,490.621z"
    />
  </svg>
);
