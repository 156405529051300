import { makeAutoObservable } from 'mobx';
import { IClientUser } from 'shared/interfaces/Admin';
import { User } from '../../../admin/Utilities/Classes/User';
import { ICustodialZone } from './ICustodialZone';

export interface ICustodialCustodian {
  _id: string;
  custodian_id: string;
  email: string;
  user: IClientUser;
  org_id: string;
  view_id: string;
  assignedTime: number;
  availableTime: number;
  notes: string;
  rates: {
    standard: number;
    overtime: number;
  };
  zones: ICustodialZone[];
}

export interface IPatchCustodian {
  _id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  availableTime?: number;

  notes?: string;
  rates?: {
    standard: number;
    overtime: number;
  };
}

export class CustodialCustodian {
  public _id: string;

  public custodian_id: string;

  public user: User;

  public org_id: string;

  public view_id: string;

  public notes: string;

  public availableTime: number;

  public rates: { standard: number; overtime: number };

  public zones: ICustodialZone[];

  constructor(params: ICustodialCustodian) {
    makeAutoObservable(this);

    this._id = params._id;
    this.custodian_id = params.custodian_id;
    this.user = new User(params.user);
    this.org_id = params.org_id;
    this.view_id = params.view_id;
    this.availableTime = params.availableTime;
    this.rates = params.rates;
    this.zones = params.zones;
    this.notes = params.notes;
  }

  public get assignedTime() {
    return this.zones.reduce((acc, val) => acc + val.time.daily, 0);
  }

  public get user_id() {
    return this.user._id;
  }
}
