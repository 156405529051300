import { makeAutoObservable } from 'mobx';
import { ApiCallsBase } from './ApiCallsBase';
import { RestVerb } from './SuperAdminAppState';

export type ApiCallParams = { [key: string]: string | number };

export class ApiCallLoader<T> {
  public apiCallsBase = new ApiCallsBase();

  public loading: boolean = false;

  public results?: T[];

  public error: string = '';

  private apiUrl: string;

  private verb: RestVerb;

  private paramsString: string = '';

  private sort?: (a: T, b: T) => number;

  constructor({
    url,
    verb,
    sort,
  }: {
    url: string;
    verb: RestVerb;
    sort?: (a: T, b: T) => number;
  }) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.verb = verb;
    this.apiUrl = url;
    this.sort = sort;
  }

  public haveParamsChanged(params: any) {
    const newParams = JSON.stringify(params);
    const oldParams = this.paramsString;
    if (newParams !== oldParams) {
      this.paramsString = newParams;
      return true;
    }
    return false;
  }

  public async upsert({
    upserts,
    params,
  }: {
    upserts: Partial<T>[];
    params: ApiCallParams;
  }): Promise<void> {
    this.setError('');
    this.setLoading(true);
    await this.apiCallsBase
      .SimplePatch<T[]>(this.apiUrl, params ?? {}, upserts)
      .then(this.setResults)
      .catch(this.setError)
      .finally(() => this.setLoading(false));
  }

  public async createOne({
    newObject,
    params,
  }: {
    newObject: Partial<T>;
    params: ApiCallParams;
  }): Promise<void> {
    this.setError('');
    this.setLoading(true);
    await this.apiCallsBase
      .SimplePost<T>(this.apiUrl, params ?? {}, newObject)
      .then((result) => this.setResults((this.results ?? []).concat([result])))
      .catch(this.setError)
      .finally(() => this.setLoading(false));
  }

  public async load({
    forceUpdate,
    params,
  }: {
    forceUpdate?: boolean;
    params?: ApiCallParams;
  }): Promise<T[] | undefined> {
    if (
      (this.results === undefined
        || this.haveParamsChanged(params)
        || forceUpdate)
      && !this.loading
    ) {
      this.setError('');
      this.setLoading(true);
      await this.apiCallsBase
        .SimpleGet<T[]>(this.apiUrl, params ?? {})
        .then(this.setResults)
        .catch(this.setError)
        .finally(() => this.setLoading(false));
    }

    return this.results;
  }

  private setLoading(loading: boolean) {
    this.loading = loading;
  }

  private setError(error: string) {
    this.error = error;
  }

  private setResults(results: T[]) {
    if (this.sort && Array.isArray(results)) {
      this.results = results.sort(this.sort);
    } else {
      this.results = results;
    }
  }

  private restCall(params?: any) {
    switch (this.verb) {
      case 'get':
        return this.apiCallsBase.SimpleGet<T[]>(this.apiUrl, params);
      case 'post':
        return this.apiCallsBase.SimplePost<T[]>(this.apiUrl, params);
      case 'put':
        return this.apiCallsBase.SimplePut<T[]>(this.apiUrl, params);
      case 'patch':
        return this.apiCallsBase.SimplePatch<T[]>(this.apiUrl, params);
      case 'delete':
        return this.apiCallsBase.SimpleDelete<T[]>(this.apiUrl, params);
    }
  }
}
